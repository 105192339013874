import React, { ReactNode, useEffect, useState } from "react";

import { RightModal } from "../../../Common/RightModal/RightModal";
import { Button } from "../../../Common/Button/Button";
import { useTranslation } from "react-i18next";

// icons
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import InformationIcon from "../../../../assets/icons/information.svg?react";
import TrashIcon from "../../../../assets/icons/trash.svg?react";
import AddIcon from "../../../../assets/icons/plus.svg?react";

import { Separator } from "../../../Common/Separator/Separator";
import { TextType } from "../../../../types/commonTypes";
import {
  PaymentAccount,
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyValueTypeEnum,
} from "../../../Payment/Payment.type";
import { InputSelect } from "../../../Common/InputSelect/InputSelect";
import { SimpleRadio } from "../../../Common/SimpleRadio/SimpleRadio";
import {
  useDepositPaymentOptionItems,
  usePaymentAccountsOptionItems,
} from "../../../Rental/Pricing/PaymentPolicy/PaymentPolicy.hooks";
import { useFetchPaymentAccounts } from "../../../../hooks/api/paymentAccounts";
import { NumberInput } from "../../../Common/NumberInput/NumberInput";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { UseModal } from "../../../../hooks/useModal";
import {
  DirectAndWebsitePolicyResponse,
  PaymentPolicyItem,
  RentalResponse,
} from "../../../../types/GETTypes";
import { usePoliciesPlatforms } from "../../../../hooks/api/paymentPolicies";
import { PaymentAccountTypeEnum } from "../../../../enums/GETenums";

export interface DirectPolicyModalProps {
  modal: UseModal<{
    policy: DirectAndWebsitePolicyResponse;
  }>;
  rental: RentalResponse;

  directPolicy: DirectAndWebsitePolicyResponse;

  setDirectPolicy: (directPolicy: DirectAndWebsitePolicyResponse) => void;
}

export default function DirectPolicyModal({
  modal,
  rental,
  directPolicy,
  setDirectPolicy,
}: DirectPolicyModalProps) {
  if (!directPolicy) return null;
  const firstPayment = directPolicy.payments_policy_items[0] ?? null;

  const { t } = useTranslation();

  const { updateDirectPolicy } = usePoliciesPlatforms();

  const [paymentIsMultiple, setPaymentIsMultiple] = useState(
    !directPolicy.is_single_payment
  );
  const [paymentAccounts, setPaymentAccounts] = useState<PaymentAccount[]>();
  const [paymentMethod, setPaymentMethod] = useState(
    firstPayment?.payment_account?.id
  );
  const [paymentTime, setPaymentTime] = useState(firstPayment?.trigger);
  const [refund, setRefund] = useState<boolean>(false);
  const [depotRequired, setDepotRequired] = useState<boolean>(false);
  const [depotPaymentMethod, setDepotPaymentMethod] = useState(
    directPolicy.deposit_payment_option
  );
  const [refundPercentage, setRefundPercentage] = useState(
    directPolicy.deposit_value
  );
  const [timeRefundTrigger, setTimeRefundTrigger] = useState(
    directPolicy.refund_condition
  );
  const [amount, setAmount] = useState<number>();
  const [accountStripe, setAccountStripe] = useState();
  const [dayBeforeArrival, setDayBeforeArrival] = useState(
    directPolicy.deposit_payment_days_delay
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<PaymentPolicyItem[]>([]);

  useEffect(() => {
    setPaymentIsMultiple(!directPolicy.is_single_payment);
    setPaymentTime(firstPayment?.trigger);
    setRefund(directPolicy.is_refundable);
    setDepotRequired(directPolicy.is_deposit_required);
    setDepotPaymentMethod(directPolicy.deposit_payment_option);
    setRefundPercentage(directPolicy.deposit_value);
    setTimeRefundTrigger(directPolicy.refund_condition);
    setAmount(directPolicy.deposit_value ?? 0);
    setDayBeforeArrival(directPolicy.deposit_payment_days_delay);
    setPayments(directPolicy.payments_policy_items);
    payments.forEach((payment, index) => {
      setPayments((prevState) => ({
        ...prevState,
        payment_account_id:
          directPolicy.payments_policy_items[index].payment_account?.id,
      }));
    });
  }, []);

  const itemsPaymentTrigger = [
    {
      label: t("Payments.PaymentTriggerOptions.atReservation"),
      value: PaymentPolicyPaymentTriggerEnum.AT_RESERVATION,
    },
    {
      label: t("Payments.PaymentTriggerOptions.atCheckin"),
      value: PaymentPolicyPaymentTriggerEnum.AT_CHECKIN,
    },
    {
      label: t("Payments.PaymentTriggerOptions.twentyFourBeforeArrival"),
      value: PaymentPolicyPaymentTriggerEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL,
    },
    {
      label: t("Payments.PaymentTriggerOptions.fiveDaysBeforeArrival"),
      value: PaymentPolicyPaymentTriggerEnum.FIVE_DAYS_BEFORE_ARRIVAL,
    },
    {
      label: t("Payments.PaymentTriggerOptions.sevenDaysBeforeArrival"),
      value: PaymentPolicyPaymentTriggerEnum.SEVEN_DAYS_BEFORE_ARRIVAL,
    },
    {
      label: t("Payments.PaymentTriggerOptions.fourteenDaysBeforeArrival"),
      value: PaymentPolicyPaymentTriggerEnum.FOURTEEN_DAYS_BEFORE_ARRIVAL,
    },
    {
      label: t("Payments.PaymentTriggerOptions.thirtyDaysBeforeArrival"),
      value: PaymentPolicyPaymentTriggerEnum.THIRTY_DAYS_BEFORE_ARRIVAL,
    },
    {
      label: t("Payments.PaymentTriggerOptions.sixtyBeforeArrival"),
      value: PaymentPolicyPaymentTriggerEnum.SIXTY_DAYS_BEFORE_ARRIVAL,
    },
  ];

  const itemsDatesTriggerRefunds = itemsPaymentTrigger.slice(1);

  const getDepositPaymentTypeInfo = (): TextType => {
    switch (depotPaymentMethod) {
      case PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION:
        return t("Payments.preAuthorisationInfo");
      case PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND:
        return t("Payments.cbRefundInfo");
      case PaymentPolicyDepositPaymentOptionEnum.BANK_TRANSFER_TO_REFUND:
        return t("Payments.sepaRefundInfo");
      default:
        return "";
    }
  };

  useEffect(() => {
    useFetchPaymentAccounts(
      (paymentAccounts: PaymentAccount[]) =>
        setPaymentAccounts(paymentAccounts),
      () => {},
      () => {},
      () => {}
    );
  }, []);

  const handleClose = (): void => {
    modal.close();
  };

  const handleSubmit = () => {
    const basicPolicy = modal.data?.policy;
    if (basicPolicy) {
      let payment_value_type_items: string[] = [];
      let payment_value_items: string[] = [];
      let payment_trigger_items: string[] = [];
      let payment_specific_date: string[] = [];
      let payment_account_id_items: string[] = [];

      if (paymentIsMultiple) {
        payments.forEach((payment) => {
          payment_value_type_items.push(payment.value_type);
          payment_value_items.push(String(payment.value));
          payment_trigger_items.push(payment.trigger);
          payment_specific_date.push("null");
          payment_account_id_items.push(String(payment.payment_account_id));
        });
      } else {
        payment_value_type_items.push(PaymentPolicyValueTypeEnum.PERCENT);
        payment_value_items.push("100");
        payment_trigger_items.push(paymentTime);
        payment_specific_date.push("null");
        payment_account_id_items.push(String(paymentMethod));
      }

      const paymentValueTypeItemsStr = payment_value_type_items.join(",");
      const paymentValueItemsStr = payment_value_items.join(",");
      const paymentTriggerItemsStr = payment_trigger_items.join(",");
      const paymentSpecificDateStr = payment_specific_date.join(",");
      const paymentAccountIdItemsStr = payment_account_id_items.join(",");

      setLoading(true);
      updateDirectPolicy(
        String(rental.id),
        basicPolicy.name,
        basicPolicy.description,
        basicPolicy.is_favorite_policy,
        !paymentIsMultiple,
        refund,
        refundPercentage,
        timeRefundTrigger as PaymentPolicyPaymentTriggerEnum,
        depotRequired,
        "FIXED",
        amount as number,
        depotPaymentMethod,
        accountStripe as any,
        dayBeforeArrival,
        "DAYS_BEFORE_ARRIVAL",
        paymentValueTypeItemsStr,
        paymentValueItemsStr,
        paymentTriggerItemsStr,
        paymentSpecificDateStr,
        paymentAccountIdItemsStr
      )
        .then((policy) => {
          setDirectPolicy(policy);
          modal.close();
        })
        .catch((err: Error) => {
          setError(err.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const prefixTranslate = (text: string, param = {}): string => {
    return t("Booking.policies.direct." + text, param);
  };

  const getRefundSettingsBlock = (): ReactNode => {
    if (refund)
      return (
        <div className={"flex items-center justify-between gap-2"}>
          <div className={"w-full"}>
            <NumberInput
              label={prefixTranslate("percent")}
              currency={"%"}
              classNames={{
                divParent: "px-5",
              }}
              onChangeText={(e) => {
                setRefundPercentage(e as number);
              }}
              value={refundPercentage}
            />
          </div>
          <div className={"w-full"}>
            <InputSelect
              required={true}
              label={prefixTranslate("ifCancel")}
              items={itemsDatesTriggerRefunds}
              onSelect={(e) => {
                // @ts-ignore
                setTimeRefundTrigger(e as string);
              }}
              selectedValue={timeRefundTrigger as string}
            />
          </div>
        </div>
      );
  };

  const getDepositSettingsBlock = (): ReactNode => {
    if (depotRequired)
      return (
        <div>
          <div className={"mt-4"}>
            <InputSelect
              required={true}
              label={t("Payments.depositPaymentOptionLabel")}
              items={useDepositPaymentOptionItems(paymentAccounts ?? [])}
              onSelect={(e) => {
                // @ts-ignore
                setDepotPaymentMethod(e as string);
                if (
                  e !==
                  PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND
                ) {
                  setDayBeforeArrival(0);
                }
              }}
              selectedValue={depotPaymentMethod}
            />
            <p className="text-xs leading-normal mt-4 tracking-wide text-low-contrast">
              {getDepositPaymentTypeInfo()}
            </p>
          </div>
          <div className={"flex flex-col gap-2"}>
            {depotPaymentMethod ===
              PaymentPolicyDepositPaymentOptionEnum.PRE_AUTHORISATION && (
              <>
                <div className={"flex justify-between items-center gap-2"}>
                  <div className={"w-full"}>
                    <NumberInput
                      label={prefixTranslate("amount")}
                      currency={"€"}
                      onChangeText={(e) => {
                        setAmount(e as number);
                      }}
                      value={amount}
                    />
                  </div>
                  <div className={"w-full"}>
                    <InputSelect
                      label={prefixTranslate("stripeAccountChoice")}
                      selectedValue={accountStripe ?? 0}
                      items={usePaymentAccountsOptionItems(
                        paymentAccounts ?? [],
                        true
                      )}
                      onSelect={(e) => {
                        // @ts-ignore
                        setAccountStripe(e);
                      }}
                    />
                  </div>
                </div>
                <p className={"text-gray-500"}>
                  {prefixTranslate("stripeInfo")}
                  <a
                    className="font-bold text-active"
                    href="https://stripe.com/fr"
                    target="_blank"
                  >
                    {prefixTranslate("is")}
                  </a>
                </p>
              </>
            )}
            {depotPaymentMethod ===
              PaymentPolicyDepositPaymentOptionEnum.CARD_PAYMENT_TO_REFUND && (
              <>
                <div className={"flex justify-between items-center gap-2"}>
                  <div className={"w-full"}>
                    <NumberInput
                      label={prefixTranslate("amount")}
                      currency={"€"}
                      value={amount}
                      onChangeText={(e) => {
                        setAmount(e as number);
                      }}
                    />
                  </div>
                  <div className={"w-full"}>
                    <InputSelect
                      label={prefixTranslate("stripeAccountChoice")}
                      selectedValue={accountStripe ?? 0}
                      items={usePaymentAccountsOptionItems(
                        paymentAccounts ?? [],
                        true
                      )}
                      onSelect={(e) => {
                        // @ts-ignore
                        setAccountStripe(e);
                      }}
                    />
                  </div>
                </div>
                <div className={"flex justify-between items-center gap-2"}>
                  <NumberInput
                    label={prefixTranslate("toPay")}
                    value={dayBeforeArrival}
                    acceptNegative={false}
                    onChangeText={(e) => {
                      setDayBeforeArrival(e as number);
                    }}
                  />
                  <p className={"text-gray-500 translate-y-2"}>
                    {prefixTranslate("dayBeforeArrival")}
                  </p>
                </div>
              </>
            )}
            {depotPaymentMethod ===
              PaymentPolicyDepositPaymentOptionEnum.BANK_CHECK_OR_CASH_AT_ARRIVAL && (
              <NumberInput
                label={prefixTranslate("amount")}
                acceptDecimal={true}
                currency={"€"}
                value={amount}
                onChangeText={(e) => {
                  setAmount(e as number);
                }}
              />
            )}
          </div>
        </div>
      );
  };

  return (
    <RightModal
      title={t("Booking.policies.direct.titleModal")}
      isVisible={modal.isVisible}
      onClose={handleClose}
    >
      <main className={"flex flex-col justify-between w-full"}>
        {/* Content */}
        <section>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <div
            className={"p-3 bg-gray-100 text-gray-500 flex gap-4 rounded-md"}
          >
            <InformationIcon className={"w-8 h-8"} />
            <p>{t("Booking.policies.direct.help")}</p>
          </div>
          <p className={"text-gray-500 mt-4 font-bold"}>
            {prefixTranslate("calendar")}
          </p>
          <div
            className={"flex items-center gap-6 mt-4 text-gray-600 text-[15px]"}
          >
            <SimpleRadio
              value={!paymentIsMultiple}
              label={prefixTranslate("paymentUnique")}
              onClick={() => {
                setPaymentIsMultiple(false);
              }}
            />
            <SimpleRadio
              value={paymentIsMultiple}
              label={prefixTranslate("multiplePayment")}
              onClick={() => {
                setPaymentIsMultiple(true);
              }}
            />
          </div>
          {paymentIsMultiple ? (
            <div className={"flex flex-col gap-2 mt-4"}>
              {payments.map((payment, index) => (
                <div
                  key={index}
                  className={
                    "bg-subtle border-1 border-element-border rounded-md p-2"
                  }
                >
                  <div className={"flex items-center justify-between"}>
                    <h1 className={"font-bold text-high-contrast"}>
                      {prefixTranslate("payment", { number: index + 1 })}
                    </h1>
                    {index > 0 && (
                      <TrashIcon
                        className={"cursor-pointer"}
                        onClick={() => {
                          setError("");
                          setPayments((prevPayments) => {
                            const updatedPayments = [...prevPayments];
                            updatedPayments.splice(index, 1);
                            return updatedPayments;
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className={"flex items-center gap-2 justify-between"}>
                    <div className={"w-full"}>
                      <InputSelect
                        label={"Type de montant"}
                        selectedValue={payments[index].value_type}
                        items={[
                          {
                            label: prefixTranslate("percentage"),
                            value: PaymentPolicyValueTypeEnum.PERCENT,
                          },
                        ]}
                        onSelect={(e) => {
                          setError("");
                          setPayments((prevPayments) => {
                            const updatedPayments = [...prevPayments];
                            updatedPayments[index].value_type =
                              e as PaymentPolicyValueTypeEnum.PERCENT;
                            return updatedPayments;
                          });
                        }}
                      />
                    </div>
                    <div className={"w-full"}>
                      <NumberInput
                        label={prefixTranslate("howMuch")}
                        currency={"%"}
                        onChangeText={(e) => {
                          setError("");
                          const newAmount = Number(e);
                          let totalPercentage = payments.reduce(
                            (total, payment) => total + payment.value,
                            0
                          );
                          const remainingPercentage =
                            100 - totalPercentage + payments[index].value;
                          const updatedAmount = Math.min(
                            newAmount,
                            remainingPercentage
                          );
                          setPayments((prevPayments) => {
                            const updatedPayments = [...prevPayments];
                            updatedPayments[index].value = updatedAmount;
                            return updatedPayments;
                          });
                        }}
                        value={payments[index].value}
                        max={100}
                        min={0}
                      />
                    </div>
                  </div>
                  <div className={"flex items-center gap-2 justify-between"}>
                    <div className={"w-full"}>
                      <InputSelect
                        required={true}
                        label={t("Payments.paymentTriggerLabel")}
                        items={itemsPaymentTrigger}
                        onSelect={(e) => {
                          setError("");
                          setPayments((prevPayments) => {
                            const updatedPayments = [...prevPayments];
                            updatedPayments[index].trigger =
                              e as PaymentPolicyPaymentTriggerEnum;
                            return updatedPayments;
                          });
                        }}
                        selectedValue={payments[index].trigger}
                        disabled={index <= 0}
                      />
                    </div>
                    <div className={"w-full"}>
                      <InputSelect
                        required={true}
                        label={prefixTranslate("paymentMethod")}
                        items={usePaymentAccountsOptionItems(
                          paymentAccounts ?? [],
                          true
                        )}
                        onSelect={(e) => {
                          setError("");
                          setPayments((prevPayments) => {
                            const updatedPayments = [...prevPayments];
                            updatedPayments[index].payment_account_id =
                              e as number;
                            return updatedPayments;
                          });
                        }}
                        selectedValue={payments[index].payment_account_id}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <Button
                RightIcon={AddIcon}
                type={"secondary"}
                onClick={() => {
                  let percentage = 100;
                  payments.map((p) => {
                    percentage -= p.value;
                  });

                  if (percentage <= 0) {
                    setError(prefixTranslate("sumPercentageError"));
                    return;
                  }

                  setPayments((prevPayments) => {
                    const updatedPayments = [...prevPayments];
                    updatedPayments.push({
                      id: 0,
                      value_type: PaymentPolicyValueTypeEnum.PERCENT,
                      value: percentage,
                      trigger: PaymentPolicyPaymentTriggerEnum.AT_CHECKIN,
                      specific_date: null,
                      payment_account_type: PaymentAccountTypeEnum.STRIPE,
                      payment_account_id: prevPayments[0].payment_account?.id,
                      created_at: "",
                      updated_at: "",
                      payment_policy_id: null,
                      payment_policy_direct_id: null,
                      payment_policy_booking_id: null,
                      payment_account: prevPayments[0].payment_account,
                    });
                    return updatedPayments;
                  });
                }}
              >
                {prefixTranslate("addPayment")}
              </Button>
            </div>
          ) : (
            <div
              className={"flex justify-between items-center gap-2 w-full mt-4"}
            >
              <div className={"w-full"}>
                <InputSelect
                  required={true}
                  label={t("Payments.paymentTriggerLabel")}
                  items={itemsPaymentTrigger}
                  onSelect={(e) => {
                    // @ts-ignore
                    console.log(e);
                    setPaymentTime(e as PaymentPolicyPaymentTriggerEnum);
                  }}
                  selectedValue={paymentTime}
                  disabled
                />
              </div>
              <div className={"w-full"}>
                <InputSelect
                  required={true}
                  label={prefixTranslate("paymentMethod")}
                  items={usePaymentAccountsOptionItems(
                    paymentAccounts ?? [],
                    true
                  )}
                  onSelect={(e) => {
                    setPaymentMethod(e as number);
                  }}
                  selectedValue={paymentMethod}
                />
              </div>
            </div>
          )}
          <Separator />
          <p className={"text-gray-500 mt-4 font-bold"}>
            {prefixTranslate("cancelCondition")}
          </p>
          <div
            className={"flex items-center gap-6 mt-4 text-gray-600 text-[15px]"}
          >
            <SimpleRadio
              value={!refund}
              label={prefixTranslate("nonRefund")}
              onClick={() => {
                setRefund(false);
              }}
            />
            <SimpleRadio
              value={refund}
              label={prefixTranslate("refund")}
              onClick={() => {
                setRefund(true);
              }}
            />
          </div>
          {getRefundSettingsBlock()}
          <Separator />
          <p className={"text-gray-500 mt-4 font-bold"}>
            {prefixTranslate("caution")}
          </p>
          <div
            className={"flex items-center gap-6 mt-4 text-gray-500 text-[15px]"}
          >
            <SimpleRadio
              value={!depotRequired}
              label={prefixTranslate("noRequired")}
              onClick={() => {
                setDepotRequired(false);
              }}
            />
            <SimpleRadio
              value={depotRequired}
              label={prefixTranslate("required")}
              onClick={() => {
                setDepotRequired(true);
              }}
            />
          </div>
          {getDepositSettingsBlock()}
        </section>

        {/* Buttons */}
        <section
          className={"flex justify-between items-center gap-2 mt-6 pb-4"}
        >
          {!loading && (
            <Button type={"secondary"} onClick={handleClose}>
              {t("Global.cancel")}
            </Button>
          )}
          <Button
            RightIcon={CheckIcon}
            onClick={handleSubmit}
            loading={loading}
          >
            {t("Global.save")}
          </Button>
        </section>
      </main>
    </RightModal>
  );
}
