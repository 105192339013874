import React, {useEffect, useState} from "react";
import {Title} from "../../components/Common/Title/Title";
import {useTranslation} from "react-i18next";
import {AutoMessageListPageProps} from "./AutoMessageListPage.type";
import {MainLayout} from "../../components/Layout/MainLayout/MainLayout";
import {TableList} from "../../components/Common/TableList/TableList";
import {useTablePage} from "../../hooks/useTablePage";
import paths from "../../constants/paths";
import {AutoMessageLineSkeleton} from "../../components/AutoMessageList/AutoMessageLineSkeleton";
import {AutoMessageListFilter} from "../../components/AutoMessageList/AutoMessageListFilter";
import {AutoMessageListHeader} from "../../components/AutoMessageList/AutoMessageListHeader";
import {AutoMessageLine} from "../../components/AutoMessageList/AutoMessageLine";
import {NoAutoMessageList} from "../../components/AutoMessageList/NoAutoMessage/NoAutoMessageList";
import {ValueType} from "../../types/commonTypes";
import {AutoMessageRemoveModal} from "../../components/AutoMessageList/AutoMessageRemoveModal";
import {useModal} from "../../hooks/useModal";
import {AutoMessageDuplicateModal} from "../../components/AutoMessageList/AutoMessageDuplicateModal";
import {AutoMessageUpsertModal} from "../../components/AutoMessage/AutoMessageUpsertModal";
import {
  AutoMessageLanguageEnum,
  AutoMessageUpsertChannelsForm,
  AutoMessageUpsertContentsForm,
  AutoMessageUpsertGeneralForm,
} from "../../components/AutoMessage/AutoMessage.type";
import {get, post} from "../../helpers/APIHelper";
import {NotificationListItemResponse, WorkspaceResponse,} from "../../types/GETTypes";
import {CheckAutoMessagesBanner} from "../../components/AutoMessageList/CheckAutoMessagesBanner";
import {WorkspaceActionModal} from "../../components/WorskspaceActionModal/WorkspaceActionModal";
import {AutoMessageSuggestionModal} from "../../components/AutoMessageList/AutoMessageSuggestionModal";
import {AutoMessagesSkeleton} from "../../components/AutoMessageList/AutoMessagesSkeleton";

export const AutoMessageListPage: React.FC<AutoMessageListPageProps> = () => {
  const wsModal = useModal();
  const [selectedWS, setSelectedWS] = useState<WorkspaceResponse | undefined>();
  const removeModal = useModal();
  const duplicateModal = useModal();
  const suggestionModal = useModal<NotificationListItemResponse>();
  const upsertModal = useModal<NotificationListItemResponse>();
  const { t } = useTranslation();


  const [loadingUpsert, setLoadingUpsert] = useState<boolean>(false);
  const [loadingRentals, setLoadingRentals] = useState<boolean>(true);
  const [errorUpsert, setErrorUpsert] = useState<string | null>(null);
  const [rentals, setRentals] = useState<ValueType[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getTitle = () => {
    return (
      <div className="flex flex-row items-center">
        <div className="flex-1">
          <Title>{t("AutoMessageList.title")}</Title>
        </div>
      </div>
    );
  };

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}`,
    "notifications",
    {
      otherMetas: [
        "total_pre_checkin",
        "total_post_checkin",
        "total_payments_cautions",
        "total_post_checkout",
        "show_progression_checking_banner",
      ],
    }
  );

  const queryParameters = new URLSearchParams(window.location.search);
  const editIdQuery = queryParameters.get("edit_id");

  const handleCheckEnabledSuggestions = async () => {
    const res = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES_SUGGESTION}`,
    );

    if (res?.data?.success) {
      const suggestions = res?.data?.result;
      const allIsEnabled: boolean = suggestions.every((item: any) => item.enabled === true);
      return allIsEnabled;
    }else {
        setError(res?.response?.data?.message);
    }
  };

  const fetchRentals = async () => {
    setLoadingRentals(true);

    const res = await get(
        `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );
    if (res?.data?.success) {
      setRentals(res?.data?.result?.rentals_access);
      setLoadingRentals(false);
      return res?.data?.result?.rentals_access;
    } else {
      setError(res?.response?.data?.message);
    }
    setLoadingRentals(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      await tablePage.fetch({ sort_field: "id", sort_direction: "desc" , });
      const isAllSuggestionsEnabled = await handleCheckEnabledSuggestions();
      const allRentals = await fetchRentals();
      setRentals(allRentals);
      if (!isAllSuggestionsEnabled && allRentals.length > 0) {
        suggestionModal.open();
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    const openAutoMessageById = async (id: string | null) => {
      const res = await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.AUTO_MESSAGES
        }/${editIdQuery}`
      );

      if (res.data?.success) {
        upsertModal.open(res.data?.result);
      }
    };

    if (editIdQuery) {
      openAutoMessageById(editIdQuery);
    }
  }, [editIdQuery]);

  const getFiltersNode = () => {
    return (
      <AutoMessageListFilter
        currentTrigger={tablePage.currentFilterCustomValue}
        preCheckinCount={
          tablePage.currentOtherMetas.find((m) => m.key === "total_pre_checkin")
            ?.value ?? ""
        }
        postCheckinCount={
          tablePage.currentOtherMetas.find(
            (m) => m.key === "total_post_checkin"
          )?.value ?? ""
        }
        paymentsCount={
          tablePage.currentOtherMetas.find(
            (m) => m.key === "total_payments_cautions"
          )?.value ?? ""
        }
        postCheckoutCount={
          tablePage.currentOtherMetas.find(
            (m) => m.key === "total_post_checkout"
          )?.value ?? ""
        }
        totalCount={tablePage.total?.toString() ?? ""}
        onAdd={handleAdd}
        onSearch={tablePage.handleChangeSearch}
        onSelect={(value) =>
          tablePage.handleFilterCustom("trigger_filter", value)
        }
        onDisplaySuggestion={() => suggestionModal.open()}
      />
    );
  };


  const handleAction = (id: ValueType, action: ValueType) => {
    if (action === "delete") {
      removeModal.open(id);
    } else if (action === "duplicate") {
      const index = tablePage.data?.findIndex((d: any) => d.id === id);
      if (index !== -1) {
        duplicateModal.open(tablePage.data[index]);
      }
    }
  };

  const handleEdit = (id: ValueType) => {
    const index = tablePage.data?.findIndex((d: any) => d.id === id);
    if (index !== -1) {
      upsertModal.open(tablePage.data[index]);
    }
  };

  const handleAdd = () => {
    wsModal.open();
  };

  const handleSelectWS = (nextWS: WorkspaceResponse) => {
    setSelectedWS(nextWS);
    upsertModal.open();
  };

  const handleRemoveSuccess = () => {
    removeModal.close();
    tablePage.fetch({
      limit: tablePage.currentNbPerPage,
      page: tablePage.currentPage,
      search: tablePage.currentSearch,
      include_ids: tablePage.currentRentalsId,
      sort_field: "id",
      sort_direction: "desc",
    });
  };

  const handleDuplicateSuccess = () => {
    duplicateModal.close();
    tablePage.fetch({
      limit: tablePage.currentNbPerPage,
      page: tablePage.currentPage,
      search: tablePage.currentSearch,
      include_ids: tablePage.currentRentalsId,
      sort_field: "id",
      sort_direction: "desc",
    });
  };

  const transformContents = (
    contents: AutoMessageUpsertContentsForm | undefined
  ) => {
    const result = [];

    if (contents?.english?.title !== "" || contents?.english?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.english,
        ...contents?.english,
      });
    }

    if (contents?.french?.title !== "" || contents?.french?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.french,
        ...contents?.french,
      });
    }

    if (contents?.spanish?.title !== "" || contents?.spanish?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.spanish,
        ...contents?.spanish,
      });
    }

    if (contents?.german?.title !== "" || contents?.german?.message !== "") {
      result.push({
        language_id: AutoMessageLanguageEnum.german,
        ...contents?.german,
      });
    }

    return result;
  };

  const handlePerformUpsert = async (data: {
    general?: AutoMessageUpsertGeneralForm | undefined;
    contents?: AutoMessageUpsertContentsForm | undefined;
    channels?: AutoMessageUpsertChannelsForm | undefined;
  }) => {
    setLoadingUpsert(true);
    setErrorUpsert(null);

    const URL = upsertModal.data
      ? `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}/${
          upsertModal.data?.id
        }`
      : `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES}`;

    const res = await post(URL, {
      _method: upsertModal.data ? "PUT" : "POST",
      workspace_id: !upsertModal.data && selectedWS?.id ? selectedWS.id : upsertModal.data?.workspace_owner,
      name: data?.contents?.name,
      trigger: data?.general?.trigger,
      delay: data?.general?.delay,
      conditions: data?.general?.conditions,
      rentals: data?.contents?.isAllRentals ? null : data?.contents?.rentalsId,
      contents: transformContents(data.contents),
      channels: {
        airbnb: data?.channels?.Airbnb.active
          ? data?.channels?.Airbnb?.value
          : null,
        booking: data?.channels?.Booking.active
          ? data?.channels?.Booking?.value
          : null,
        direct: data?.channels?.Direct.active
          ? data?.channels?.Direct?.value
          : null,
      },
    });

    if (res.data?.success) {
      if (!upsertModal.data) {
        //enabled created automated message
        try {
          await post(
            `${import.meta.env.VITE_API_URL}${paths.API.AUTO_MESSAGES_ENABLE}`,
            { notification_id: res?.data?.result?.id }
          );
        } catch (e) {
          console.log("🚀 ~ e:", e);
        }
      }
      upsertModal.close();
      /*
      tablePage.fetch({
        limit: tablePage.currentNbPerPage,
        page: tablePage.currentPage,
        search: tablePage.currentSearch,
        include_ids: tablePage.currentRentalsId,
      });

       */
      tablePage.handleFilterCustom(
        "trigger_filter",
        tablePage.currentFilterCustomValue
      );
    } else {
      setErrorUpsert(res?.response?.data?.message);
    }

    setLoadingUpsert(false);
  };


  const isBannerVisible = () => {
    return (tablePage.currentOtherMetas.find(
      (m) => m.key === "show_progression_checking_banner"
    )?.value ?? false) as boolean;
  };

  return (
    <>
      <AutoMessageRemoveModal
        id={removeModal.data}
        isVisible={removeModal.isVisible}
        onSuccess={handleRemoveSuccess}
        onClose={removeModal.close}
      />

      <AutoMessageDuplicateModal
        data={duplicateModal.data}
        isVisible={duplicateModal.isVisible}
        onSuccess={handleDuplicateSuccess}
        onClose={duplicateModal.close}
      />
      <AutoMessageSuggestionModal
          isVisible={suggestionModal.isVisible}
          onSuccess={handleDuplicateSuccess}
          onClose={suggestionModal.close}
      />

      <WorkspaceActionModal
        action="automated_messages"
        isVisible={wsModal.isVisible}
        onClose={wsModal.close}
        onSelectWS={handleSelectWS}
      />

      <AutoMessageUpsertModal
        isVisible={upsertModal.isVisible}
        data={upsertModal.data}
        loading={loadingUpsert}
        error={errorUpsert}
        onClose={() => {
          upsertModal.close();
          setErrorUpsert(null);
        }}
        onUpsert={handlePerformUpsert}
      />

      <MainLayout
        title={getTitle()}
        sidebarActiveItem="auto_messages"
        sidebarActiveSubItem="auto_messages"
      >
        <div>
          <CheckAutoMessagesBanner isVisible={isBannerVisible()} />

          {
            !loadingRentals && rentals && rentals.length === 0 && (
               <NoAutoMessageList/>
            )
          }


          {
              loadingRentals &&
              (
                 <AutoMessagesSkeleton/>
              )
          }

                    {
                        rentals && rentals.length > 0 && (
                            <div className="mt-4">
                              <TableList
                                  i18nElement="AutoMessageList.autoMessage"
                                  Filters={getFiltersNode()}
                                  Header={AutoMessageListHeader}
                                  Item={AutoMessageLine}
                                  Skeleton={AutoMessageLineSkeleton}
                                  NoData={<NoAutoMessageList onAdd={handleAdd}/>}
                                  tablePage={tablePage}
                                  onClick={handleEdit}
                                  onClickAction={handleAction}
                              />
                            </div>
                        )
                    }

                  </div>
            </MainLayout>
            </>
            );
          };
