import paths from "../../constants/paths";
import { del, get, post } from "../../helpers/APIHelper";
import {
  AutomationSettingsPayload,
  AutomationSettingsResponse,
} from "../../pages/ManagementAutomationList/ManagementAutomationList.type";
import { ValueType } from "../../types/commonTypes";
import {
  AutomationItemResponse,
  ChecklistType,
  TaskAssignationType,
} from "../../types/GETTypes";
import { ReservationForCalendarList } from "../../pages/Booking/BookingPage.type";

export type AutomationCreatePayload = {
  enabled: boolean;
  rental_id: ValueType;
  type: ChecklistType;
  checklist_id: number | null;
  name: string | null;
  description: string | null;
  amount_global: number | null;
  remarks: string;
  trigger: "checkin" | "checkout";
  assignation_type: "OPERATORS" | "TEAMS";
  assignee_ids: ValueType[];
};

export type AutomationUpdatePayload = {
  enabled: boolean;
  rental_id: ValueType;
  type: ChecklistType;
  checklist_id: number | null;
  name: string | null;
  description: string | null;
  remarks: string;
  trigger: "checkin" | "checkout";
  assignation_type: "OPERATORS" | "TEAMS";
  assignee_ids: ValueType[];
};

export type AutomationDeleteMultiplePayload = {
  ids: ValueType[];
  delete_tasks: boolean;
};

export type AutomationAssignMultiplePayload = {
  ids: ValueType[];
  assignation_type: "OPERATORS" | "TEAMS";
  assignee_ids: ValueType[];
};

export const useAutomation = ({
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  onSuccess: (data?: AutomationItemResponse | AutomationItemResponse[]) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  /**
   * Asynchronously creates an automation by sending a POST request to the specified API endpoint.
   *
   * @param {Object} param - The parameter object.
   * @param {AutomationCreatePayload} param.automation - The payload containing automation details to be created.
   *
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   *
   * The function performs the following steps:
   * 1. Calls the `onStart` function if it is defined.
   * 2. Constructs the API URL using environment variables and predefined paths.
   * 3. Sends a POST request with the automation payload.
   * 4. Calls `onSuccess` with the result if the request is successful.
   * 5. Calls `onError` with an error message if the request fails.
   * 6. Calls the `onEnd` function if it is defined, regardless of success or failure.
   */
  const create = async ({
    automation,
  }: {
    automation: AutomationCreatePayload;
  }) => {
    if (typeof onStart === "function") {
      onStart();
    }
    console.log(automation);

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.GET_AUTOMATIONS
      }`;
      const res = await post(URL, automation);
      console.log(res);
      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        throw new Error(res?.response?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Updates an automation with the given ID and payload.
   *
   * @param {Object} params - The parameters for the update function.
   * @param {ValueType} params.automationId - The ID of the automation to update.
   * @param {AutomationUpdatePayload} params.automationPayload - The payload containing the update data for the automation.
   *
   * @returns {Promise<void>} A promise that resolves when the update operation is complete.
   *
   * The function performs the following steps:
   * 1. Calls the `onStart` function if it is defined.
   * 2. Constructs the URL for the update request using the provided `automationId`.
   * 3. Sends a POST request with a `_method` of "PUT" to update the automation.
   * 4. Calls `onSuccess` with the result if the update is successful.
   * 5. Calls `onError` with an error message if the update fails.
   * 6. Calls the `onEnd` function if it is defined, regardless of success or failure.
   */
  const update = async ({
    automationId,
    automationPayload,
  }: {
    automationId: ValueType;
    automationPayload: AutomationUpdatePayload;
  }) => {
    if (typeof onStart === "function") {
      onStart();
    }

    if (automationId) {
      try {
        const URL = `${import.meta.env.VITE_API_URL}${
          paths.API.OPERATIONNAL_MANAGEMENT.GET_AUTOMATIONS
        }/${automationId}`;
        const res = await post(URL, {
          _method: "PUT",
          ...automationPayload,
        });
        if (res?.data?.success) {
          onSuccess(res.data.result);
        } else {
          onError(res?.data?.message ?? res.message);
        }
      } catch (error: any) {
        onError(error.message);
      } finally {
        if (typeof onEnd === "function") {
          onEnd();
        }
      }
    }
  };

  /**
   * Asynchronously removes an automation by its ID.
   *
   * This function sends a DELETE request to the server to remove the specified automation.
   * It optionally deletes associated tasks based on the `deleteTasks` parameter.
   * The function handles the request lifecycle by invoking `onStart`, `onSuccess`, `onError`, and `onEnd` callbacks.
   *
   * @param {ValueType} automationId - The unique identifier of the automation to be removed.
   * @param {boolean} deleteTasks - A flag indicating whether to delete associated tasks.
   */
  const remove = async (automationId: ValueType, deleteTasks: boolean) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.GET_AUTOMATIONS
      }/${automationId}`;
      const res = await del(URL, {
        method: "DELETE",
        data: {
          delete_tasks: deleteTasks,
        },
      });
      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Asynchronously removes multiple automation entries.
   *
   * This function initiates the removal process by calling the `onStart` callback if it is defined.
   * It constructs the API URL using environment variables and predefined paths, then sends a POST request
   * with the provided data. Upon a successful response, it calls the `onSuccess` callback with the result.
   * If an error occurs, it calls the `onError` callback with the error message. Finally, it calls the `onEnd`
   * callback if it is defined.
   *
   * @param {AutomationDeleteMultiplePayload} data - The payload containing the data necessary for deletion.
   */
  const removeMultiple = async (data: AutomationDeleteMultiplePayload) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.DELETE_MULTIPLE_AUTOMATION
      }`;
      const res = await post(URL, data);
      if (res?.data?.success) {
        onSuccess(res.data.result.automations);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Enables an automation by its ID.
   *
   * This function attempts to enable an automation using the provided automation ID.
   * It triggers the `onStart` callback before starting the operation, and the `onEnd`
   * callback after the operation completes, regardless of success or failure.
   *
   * @param {ValueType} automationId - The ID of the automation to be enabled.
   *
   * The function performs the following steps:
   * 1. Calls the `onStart` function if it is defined.
   * 2. Constructs the URL for the API request using the environment variable `VITE_API_URL`
   *    and the path `paths.API.OPERATIONNAL_MANAGEMENT.GET_AUTOMATIONS`.
   * 3. Sends a POST request to the constructed URL to enable the automation.
   * 4. If the request is successful and the response indicates success, calls the `onSuccess`
   *    function with the result.
   * 5. If the request fails or the response indicates failure, calls the `onError` function
   *    with the error message.
   * 6. Calls the `onEnd` function if it is defined, after the operation completes.
   */
  const enable = async (automationId: ValueType) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.GET_AUTOMATIONS
      }/${automationId}/enable`;
      const res = await post(URL);
      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Disables an automation by its ID and optionally deletes associated tasks.
   *
   * @param {ValueType} automationId - The ID of the automation to disable.
   * @param {boolean} deleteTasks - Flag indicating whether to delete associated tasks.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const disable = async (automationId: ValueType, deleteTasks: boolean) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.GET_AUTOMATIONS
      }/${automationId}/disable`;
      const res = await post(URL, {
        delete_tasks: deleteTasks,
      });
      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Disables multiple automations based on the provided automation IDs.
   * Optionally deletes associated tasks if specified.
   *
   * @param {ValueType[]} automationIds - An array of automation IDs to be disabled.
   * @param {boolean} deleteTasks - A flag indicating whether to delete associated tasks.
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const disableMultiple = async (
    automationIds: ValueType[],
    deleteTasks: boolean,
  ) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.DISABLE_MULTIPLE_AUTOMATION
      }`;
      const res = await post(URL, {
        ids: automationIds,
        delete_tasks: deleteTasks,
      });
      if (res?.data?.success) {
        onSuccess(res.data.result.automations);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  /**
   * Asynchronously assigns multiple automation tasks using the provided payload.
   *
   * This function will trigger the `onStart` callback if it is defined, then attempt to
   * post the payload to a specified API endpoint. Upon receiving a response, it will
   * call `onSuccess` if the operation was successful, or `onError` if there was an error.
   * Finally, it will trigger the `onEnd` callback if it is defined.
   *
   * @param {AutomationAssignMultiplePayload} payload - The data to be sent to the API for assigning multiple automation tasks.
   *
   * @returns {Promise<void>} A promise that resolves when the operation is complete.
   */
  const assignMultiple = async (payload: AutomationAssignMultiplePayload) => {
    if (typeof onStart === "function") {
      onStart();
    }

    try {
      const URL = `${import.meta.env.VITE_API_URL}${
        paths.API.OPERATIONNAL_MANAGEMENT.ASSIGN_MULTIPLE_AUTOMATION
      }`;
      const res = await post(URL, payload);
      if (res?.data?.success) {
        onSuccess(res.data.result);
      } else {
        onError(res?.data?.message ?? res.message);
      }
    } catch (error: any) {
      onError(error.message);
    } finally {
      if (typeof onEnd === "function") {
        onEnd();
      }
    }
  };

  return {
    create,
    update,
    remove,
    removeMultiple,
    enable,
    disable,
    disableMultiple,
    assignMultiple,
  };
};

export const useUpdateAutomationSettings = async ({
  data,
  onSuccess,
  onError,
  onStart,
  onEnd,
}: {
  data: AutomationSettingsPayload;
  onSuccess: (automationSettings?: AutomationSettingsResponse) => void;
  onError: (message: string | null) => void;
  onStart?: () => void;
  onEnd?: () => void;
}) => {
  if (typeof onStart === "function") {
    onStart();
  }

  try {
    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.UPDATE_AUTOMATION_SETTINGS
    }`;
    const res = await post(URL, data);
    if (res?.data?.success) {
      onSuccess(res.data.result);
    } else {
      onError(res?.data?.message ?? res.message);
    }
  } catch (error: any) {
    onError(error.message);
  } finally {
    if (typeof onEnd === "function") {
      onEnd();
    }
  }
};

/**
 * Function for interact with automations settings.
 */
export const useAutomationSettings = () => {
  const getSettings = async (): Promise<AutomationSettingsPayload> => {
    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.UPDATE_AUTOMATION_SETTINGS
    }`;
    const res = await get(URL);
    if (res?.data?.success) {
      return res.data.result;
    } else {
      throw new Error(res?.data?.message ?? res.message);
    }
  };

  return {
    getSettings,
  };
};

/**
 * Hook for interact with automation
 * api in cleaning feature.
 */
export const useTasksCleaning = () => {
  /**
   * Function API for assign cleaning task in
   * reservation.
   *
   * @param reservationId
   * @param assignationType
   * @param id
   */
  const assign = async (
    reservationId: string,
    assignationType: TaskAssignationType,
    id: string,
  ): Promise<ReservationForCalendarList> => {
    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.ASSIGN_CLEANING
    }`;

    const response = await post(URL, {
      id: String(id),
      assignation_type: assignationType,
      reservation_id: reservationId,
    });

    if (response?.data?.success) {
      return response.data.result;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  const removeAssignTask = async (
    reservationId: string | number,
  ): Promise<ReservationForCalendarList> => {
    const URL = `${import.meta.env.VITE_API_URL}${
      paths.API.OPERATIONNAL_MANAGEMENT.ASSIGN_CLEANING
    }`;

    const response = await del(URL, {
      data: {
        reservation_id: String(reservationId),
      },
    });

    if (response?.data?.success) {
      return response.data.result;
    } else {
      throw new Error(response?.data?.message ?? response.message);
    }
  };

  return {
    assign,
    removeAssignTask,
  };
};
