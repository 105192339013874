import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import paths from "../../../constants/paths";
import { useTablePage } from "../../../hooks/useTablePage";
import { SimpleBox } from "../../Modal/Airbnb/Connect/AirbnbConnectStepImport";
import { Button } from "../Button/Button";
import { TableList } from "../TableList/TableList";
import { TextInput } from "../TextInput/TextInput";

import ArrowRightIcon from "../../../assets/icons/arrow-right-white.svg?react";
import SearchIcon from "../../../assets/icons/search.svg?react";
import {
  RentalLightListItemResponse,
  RentalResponse,
} from "../../../types/GETTypes";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import {
  TableListHeaderProps,
  TableListItemProps,
} from "../TableList/TableList.type";
import { MultiSelectProps } from "./MultiSelect.type";

export const MultiSelect: React.FC<MultiSelectProps> = ({
  selectedRentals,
  loading,
  register,
  error,
  options = {
    withInfoText: true,
    i18nKeySubmitButtonText: undefined,
    withSubmitButton: true,
  },
  onRentalsIsLoaded,
  onSelectRental,
  onNext,
}) => {
  const { t } = useTranslation();

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
    "rentals_access"
  );

  useEffect(() => {
    tablePage.fetch({});
  }, []);

  useEffect(() => {
    if (tablePage.data && typeof onRentalsIsLoaded === "function") {
      onRentalsIsLoaded(tablePage.data);
    }
  }, [tablePage.data]);

  return (
    <div className="flex flex-col gap-1">
      {options?.withInfoText ? (
        <div className="p-4 bg-element-background border-1 border-element-border rounded-8px">
          <p className="font-semibold text-high-contrast">
            {t("Progression.RentalsRules.Multi.content")}
            <br />
            {t("Progression.RentalsRules.Multi.list1")}
            <br />
            {t("Progression.RentalsRules.Multi.list2")}
          </p>
        </div>
      ) : null}

      <div className="mt-4">
        <TextInput
          placeholder={t("Global.search")}
          Icon={SearchIcon}
          onChangeText={tablePage.handleChangeSearch}
        />
      </div>

      <TableList
        Header={MultiSelectHeader}
        Item={MultiSelectItem}
        NoData={null}
        Skeleton={MultiSelectSkeleton}
        i18nElement="Global.rentals"
        tablePage={tablePage}
        selectedItems={selectedRentals}
        onClick={onSelectRental}
      />

      {options?.withSubmitButton ? (
        <div className="mt-4">
          <Button
            onClick={onNext}
            RightIcon={ArrowRightIcon}
            loading={loading}
            disabled={Boolean(error)}
          >
            {options.i18nKeySubmitButtonText
              ? t(options.i18nKeySubmitButtonText)
              : t("Global.save")}
          </Button>
        </div>
      ) : null}

      {error ? <ErrorMessage errorType="FORM">{error}</ErrorMessage> : null}
    </div>
  );
};

export const MultiSelectHeader: React.FC<TableListHeaderProps> = ({
  areAllSelected,
  onSelectAll,
}) => {
  const { t } = useTranslation();

  return (
    <tr
      className="bg-element-background border-b-1 border-element-border"
      onClick={onSelectAll}
    >
      <th className="w-8 p-2">
        <SimpleBox value={areAllSelected ?? false} />
      </th>

      <th className="p-2 text-left">
        <p className="font-semibold text-high-contrast">
          {t("Progression.RentalsRules.Multi.rentals")}
        </p>
      </th>
    </tr>
  );
};

export const MultiSelectItem: React.FC<TableListItemProps> = ({
  data,
  isSelected,
  onClick,
}) => {
  {
    /* TODO: Can be better implemented */
  }
  const rental: RentalResponse | null = "details" in data ? data : null;
  const rentalLight: RentalLightListItemResponse | null =
    "enabled" in data ? data : null;

  return (
    <tr
      className="cursor-pointer"
      onClick={() => onClick(rental?.id! ?? rentalLight?.id)}
    >
      <td className="w-8 p-2">
        <SimpleBox value={isSelected ?? false} />
      </td>

      <td className="p-2">
        <div className="flex items-center gap-2">
          <img
            alt="Rental thumbnail"
            src={rental?.details?.photos?.cover ?? rentalLight?.cover}
            className="w-12 h-12 rounded-6px border-1 border-element-border-light"
          />

          <div className="flex flex-col">
            <p className="font-semibold text-high-contrast">
              {rental?.details?.informations?.name ?? rentalLight?.name}
            </p>
            <p className="font-light text-low-contrast">
              {rental?.details?.informations?.address ?? rentalLight?.address}
            </p>
            <p className="font-light text-low-contrast">
              {rental?.details?.informations?.postal_code ??
                rentalLight?.postal_code}{" "}
              {rental?.details?.informations?.city ?? rentalLight?.city}{" "}
              {rental?.details?.informations?.country?.name ??
                rentalLight?.country?.name}
            </p>
          </div>
        </div>
      </td>
    </tr>
  );
};

const MultiSelectSkeleton: React.FC<{}> = () => {
  return (
    <tr>
      <td className="w-8 p-2">
        <SimpleBox value={false} disabled={true} />
      </td>

      <td className="p-2">
        <div className="flex items-center gap-2">
          <div className="w-12 h-12 rounded-6px border-1 border-element-border-light bg-slate-200" />

          <div className="flex flex-col">
            <p className="h-2 rounded w-72 bg-slate-300"></p>
            <p className="h-2 mt-1 rounded w-72 bg-slate-200"></p>
            <p className="h-2 mt-1 rounded w-52 bg-slate-200"></p>
          </div>
        </div>
      </td>
    </tr>
  );
};
