import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { Button } from "../../Common/Button/Button";

import CheckinIcon from "../../../assets/icons/log-in.svg?react";
import UserCheckIcon from "../../../assets/icons/user-check.svg?react";
import UserAddIcon from "../../../assets/icons/user-plus.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import SpinIcon from "../../../assets/icons/loader.svg?react";
import CheckListBlueIcon from "../../../assets/icons/check-list-blue.svg?react";

import { CalendarReservationResponse } from "../../../types/GETTypes";
import { UseModal } from "../../../hooks/useModal";
import { useTasksCleaning } from "../../../hooks/api/automation";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import moment from "moment/moment";
import { Badge } from "../../Common/Badge/Badge";
import { FlexCenterBetween } from "../../Common/FlexComponents/FlexCenterBetween";
import { FlexItemCenter } from "../../Common/FlexComponents/FlexItemCenter";
import { cn } from "../../../helpers/classHelper";

export default function CalendarOperationalManagement({
  reservation,
  modal,
  reloadCalendarList = () => {},
}: {
  reservation: CalendarReservationResponse;
  modal: UseModal<{ reservationId: string }>;
  reloadCalendarList?: () => void;
}) {
  const { t } = useTranslation();
  const { removeAssignTask } = useTasksCleaning();

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const hasCleaningAssigned = (): boolean => {
    return reservation.cleaning?.status === "ASSIGNED";
  };

  const handleClickAssignOperators = (): void => {
    modal.open({
      reservationId: reservation.id as string,
    });
  };

  const hasManualCodeLock = (): boolean => {
    return reservation.rental.services?.locks.manual_codes.length >= 1;
  };

  const hasDeviceCodeLock = (): boolean => {
    return reservation.rental.services?.locks.devices.length >= 1;
  };

  const handleRemoveTask = (): void => {
    const reservationId = reservation.id;
    setLoading(true);
    removeAssignTask(reservationId)
      .then((reservationResponse) => {
        reservation.cleaning = reservationResponse.cleaning;
        if (typeof reloadCalendarList === "function") {
          reloadCalendarList();
        }
      })
      .catch((error) => {
        setError(error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="bg-element-background rounded-6px p-2 mt-4">
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <div className="flex items-center">
          <CheckinIcon className="w-5 h-5 mr-1" />

          <p className="text-low-contrast font-light">
            {t("Booking.checkinCheckout")}
          </p>
        </div>
        <section className={"mt-4"}>
          <div className={"flex items-center justify-between w-full"}>
            <div className={"flex flex-col w-full"}>
              <p className="text-low-contrast mb-2">
                {t("Calendar.Reservation.checkin")}
              </p>
              <div className={"flex  justify-between w-full"}>
                <div>
                  {hasManualCodeLock() && (
                    <div className="font-semibold text-low-contrast mb-2 bg-gray-300/20 rounded p-2 px-3">
                      {t("Calendar.Reservation.withCodelock")}
                    </div>
                  )}
                  {hasDeviceCodeLock() &&
                    reservation.rental.services?.locks.devices.map((device) => (
                      <div className="font-semibold text-low-contrast mb-2 bg-gray-300/20 rounded p-2 px-3">
                        {t("Calendar.Reservation.notDefined")}
                      </div>
                    ))}
                  {!hasDeviceCodeLock() && !hasManualCodeLock() && (
                    <div className="gap-1 font-semibold text-low-contrast mb-2 bg-gray-300/20 rounded p-2 px-3">
                      {moment(reservation.checkin).format("DD/MM/YYYY")}{" "}
                      {reservation.time_in}
                    </div>
                  )}
                </div>
                <div className={"flex flex-col w-1/2"}>
                  {hasManualCodeLock() &&
                    reservation.rental.services?.locks.manual_codes.map(
                      (manualLock) => (
                        <div
                          className={"flex items-center justify-between w-full"}
                        >
                          <p className="text-low-contrast mb-2 text-xs">
                            {t("Calendar.Reservation.codelock")}
                          </p>
                          <p className="mb-2 text-red-500 text-lg font-bold">
                            {manualLock.code}
                          </p>
                        </div>
                      ),
                    )}
                  {hasDeviceCodeLock() &&
                    reservation.rental.services?.locks.devices.map((device) => (
                      <div
                        className={"flex items-center justify-between w-full"}
                      >
                        <p className="text-low-contrast mb-2 text-xs">
                          {t("Calendar.Reservation.codeLockName", {
                            name: device.name,
                          })}
                        </p>
                        <p className="mb-2 text-red-500 text-lg font-bold">
                          {device.static_code}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className={"mt-5"}>
            <div className={"flex items-center justify-between"}>
              <div className={"flex flex-col"}>
                <div className="text-low-contrast mb-2">
                  <p>{t("Calendar.Reservation.checkout")}</p>
                </div>
                <div className="font-semibold flex items-center text-low-contrast mb-2 bg-gray-300/20 rounded p-2 px-3 items-center gap-2 flex">
                  <UserCheckIcon />
                  {moment(reservation.checkout).format("DD/MM/YYYY")}{" "}
                  {reservation.time_out}
                </div>
              </div>
            </div>
          </div>
          <div className={"mt-5"}>
            <div className={"flex items-end justify-between"}>
              <div
                className={cn(
                  "flex flex-col",
                  hasCleaningAssigned() && "w-full",
                )}
              >
                <p className="text-low-contrast mb-2">
                  {t("Calendar.Reservation.cleaning")}
                </p>
                {!hasCleaningAssigned() && (
                  <p className="font-semibold text-low-contrast mb-2 bg-gray-300/20 rounded p-2 px-3">
                    {t("Calendar.Reservation.noScheduled")}
                  </p>
                )}
                {hasCleaningAssigned() && (
                  <FlexCenterBetween
                    className={
                      "w-full p-3 px-6 pt-4 rounded-lg border border-gray-300 bg-white relative"
                    }
                  >
                    {loading ? (
                      <SpinIcon
                        className={
                          "absolute top-0.5 right-0.5 w-5 animate-spin text-gray-500"
                        }
                      />
                    ) : (
                      <CloseIcon
                        onClick={() => handleRemoveTask()}
                        className={
                          "absolute top-0.5 right-0.5 w-5 cursor-pointer"
                        }
                      />
                    )}
                    <p className={"font-bold w-[110px]"}>
                      {t("Automation.AssignModal.date", {
                        date: reservation.cleaning.date,
                        time: reservation.cleaning.time.replace(":", "h"),
                      })}
                    </p>
                    <FlexItemCenter gap={10} className={"font-bold"}>
                      {reservation.cleaning.photo ? (
                        <img
                          alt="operator photo"
                          src={reservation.cleaning.photo}
                          height={30}
                          width={30}
                          className="rounded-full w-[30px] h-[30px] object-cover border"
                        />
                      ) : (
                        <>
                          <div className="rounded-full w-[30px] h-[30px] object-cover bg-gray-100 border"></div>
                        </>
                      )}
                      <p className={" w-[100px]"}>
                        {reservation.cleaning.name}
                      </p>
                    </FlexItemCenter>
                    <div className="flex gap-2 bg-[#EBF0F9] rounded-6px items-center w-fit p-3">
                      <CheckListBlueIcon className="size-4" />
                      <p className="text-[#232F6F] font-semibold">
                        {t("Task.List.scheduled")}
                      </p>
                    </div>
                  </FlexCenterBetween>
                )}
              </div>
              {!hasCleaningAssigned() && (
                <div className={"-translate-y-2"}>
                  <Button
                    onClick={handleClickAssignOperators}
                    RightIcon={UserAddIcon}
                    type={"secondary"}
                  >
                    {t("Calendar.Reservation.assignTo")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
