import React from "react";
import { UseModal, useModal } from "../../hooks/useModal";
import { UpsertCustomURLModal } from "../AddWebsite/UpsertCustomURLModal";
import { AddWebsiteRemoveCustomURLModal } from "../AddWebsite/AddWebsiteRemoveCustomURLModal";
import { useTranslation } from "react-i18next";
import { TextInput } from "../Common/TextInput/TextInput";
import { Badge } from "../Common/Badge/Badge";
import { Button } from "../Common/Button/Button";
import { Separator } from "../Common/Separator/Separator";
import EditIcon from "../../assets/icons/edit.svg?react";
import TrashIcon from "../../assets/icons/trash-active.svg?react";
import InfoIcon from "../../assets/icons/information.svg?react";
import PlusIcon from "../../assets/icons/plus.svg?react";

export const WebsiteURL: React.FC<{
  customURL: string | undefined;
  URL: string;
  URLError: string | undefined;
  onChangeURL: (value: string) => void;
  onChangeCustomURL: (value: string | undefined) => void;
}> = ({ customURL, URL, URLError, onChangeURL, onChangeCustomURL }) => {
  const prefix = window.location.origin + "/website/";

  const upsertCustomModal = useModal<string>();
  const removeCustomModal = useModal();

  const handleCurrentURL = (text: string) => {
    if (!text.startsWith(prefix)) {
      onChangeURL(prefix);
      return;
    }

    const urlSuffix = text.slice(prefix.length);

    const cleanSuffix = urlSuffix.replace(/[^a-z0-9-_]/gi, "").toLowerCase();

    onChangeURL(`${prefix}${cleanSuffix}`);
  };

  const handleRemove = () => {
    onChangeCustomURL(undefined);
    removeCustomModal.close();
  };

  return (
    <>
      <UpsertCustomURLModal
        url={upsertCustomModal.data}
        isVisible={upsertCustomModal.isVisible}
        onClose={upsertCustomModal.close}
        onChange={onChangeCustomURL}
      />

      <AddWebsiteRemoveCustomURLModal
        isVisible={removeCustomModal.isVisible}
        onClose={removeCustomModal.close}
        onRemove={handleRemove}
      />

      {customURL ? (
        <WebsiteURLCustom
          currentCustomURL={customURL}
          upsertCustomModal={upsertCustomModal}
          removeCustomModal={removeCustomModal}
        />
      ) : (
        <WebsiteURLSuperHote
          URL={URL}
          error={URLError}
          onChangeURL={handleCurrentURL}
          onOpenCustom={upsertCustomModal.open}
        />
      )}
    </>
  );
};

const WebsiteURLCustom: React.FC<{
  currentCustomURL: string;
  upsertCustomModal: UseModal<string>;
  removeCustomModal: UseModal<unknown>;
}> = ({ currentCustomURL, upsertCustomModal, removeCustomModal }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-2 items-end">
        <TextInput
          type="clipboard"
          label={t("Website.Add.URL.customLabel")}
          value={currentCustomURL}
        />

        <Badge
          status="success"
          label={t("Website.Add.URL.saved")}
          size="small"
        />
      </div>

      <Button
        type="secondary"
        RightIcon={EditIcon}
        onClick={() => upsertCustomModal.open(currentCustomURL)}
      >
        {t("Website.Add.URL.editCustom")}
      </Button>

      <Button
        type="alert"
        RightIcon={TrashIcon}
        onClick={removeCustomModal.open}
      >
        {t("Website.Add.URL.removeCustom")}
      </Button>
    </div>
  );
};

const WebsiteURLSuperHote: React.FC<{
  URL: string;
  error: string | undefined;
  onChangeURL: (value: string) => void;
  onOpenCustom: () => void;
}> = ({ URL, error, onChangeURL, onOpenCustom }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="rounded-6px bg-element-border p-2 flex gap-2">
        <InfoIcon className="size-6" />

        <div className="flex flex-col gap-1">
          <p className="text-high-contrast font-semibold mt-0.5">
            {t("Website.Add.URL.infosTitle")}
          </p>
          <p className="text-low-contrast font-light">
            {t("Website.Add.URL.infosDescription")}
          </p>
        </div>
      </div>

      <div className="h-4" />

      <TextInput
        label={t("Website.Add.URL.label")}
        value={URL}
        onChangeText={onChangeURL}
        error={error}
      />

      <p className="mt-1 font-light text-low-contrast text-xs">
        {t("Website.Add.URL.hint")}
      </p>

      <Separator classNames={{ divParent: "py-2" }} />

      <p className="mt-1 font-light text-low-contrast text-center">
        {t("Website.Add.URL.custom")}
      </p>

      <div className="mt-4">
        <Button type="secondary" RightIcon={PlusIcon} onClick={onOpenCustom}>
          {t("Website.Add.URL.addCustom")}
        </Button>
      </div>
    </>
  );
};
