import CheckIcon from "../../../assets/icons/check-circle-green.svg?react";
import PendingIcon from "../../../assets/icons/clock-blue.svg?react";
import AlertIcon from "../../../assets/icons/warning-triangle-red.svg?react";

import React from "react";
import { cn } from "../../../helpers/classHelper";

export default function TaskAssignWithStatus({
  title,
  subTitle,
  status,
  imgUrl,
}: {
  title: string;
  subTitle: string;
  status: "pending" | "success" | "alert";
  imgUrl: string;
}) {
  return (
    <>
      <div
        style={{
          background: "url('" + imgUrl + "') center no-repeat",
          backgroundSize: "80px 80px",
        }}
        className={"w-[45px] h-[45px] relative rounded-full border"}
      >
        <div
          className={"bg-white absolute rounded-full -bottom-0.5 -right-0.5"}
        >
          <div
            className={cn("rounded-full p-[1px]", {
              "bg-blue-100": status === "pending",
              "bg-success/20": status === "success",
              "bg-red-200": status === "alert",
            })}
          >
            {status === "pending" && <PendingIcon />}
            {status === "success" && <CheckIcon />}
            {status === "alert" && <AlertIcon />}
          </div>
        </div>
      </div>
      <div>
        <p
          className={cn("font-bold", {
            "text-blue-800": status === "pending",
            "text-success": status === "success",
            "text-red-500": status === "alert",
          })}
        >
          {title}
        </p>
        <p
          className={cn("", {
            "text-blue-800": status === "pending",
            "text-success": status === "success",
            "text-red-500": status === "alert",
          })}
        >
          {subTitle}
        </p>
      </div>
    </>
  );
}
