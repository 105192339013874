import moment from "moment";
import React from "react";
import { Controller } from "react-hook-form";
import ArrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg?react";
import { RequiredFields } from "../../../types/commonTypes";
import { Button } from "../../Common/Button/Button";
import { CalendarInput } from "../../Common/CalendarInput/CalendarInput";
import { CalendarInputValueRange } from "../../Common/CalendarInput/CalendarInput.type";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { InputSelect } from "../../Common/InputSelect/InputSelect";
import { NumberInput } from "../../Common/NumberInput/NumberInput";

import { t } from "i18next";
import {
  AddReservationInfosForm,
  AddReservationInfosProps,
} from "./AddReservationInfos.type";
import { MultipleRestrictionsGet, Restriction } from "../../../types/GETTypes";

export const AddReservationInfos: React.FC<AddReservationInfosProps> = ({
  loading,
  form,
  rentalsAccess,
  restrictions,
  rentalChoiced,
  error,
  onSelectDefaultRental,
  onCancel,
  onNext,
}) => {
  const requiredFields: RequiredFields<AddReservationInfosForm> = {
    rental: true,
    type: true,
    adults: true,
    children: true,
    dates: true,
    checkinTime: true,
    checkoutTime: true,
  };

  // const [alerts, setAlerts] = useState<object>({});

  form.watch();

  // ! A QUOI SERT LA PARTIE AVEC LES ALERTES (je l'ai mis en commentaire, le type n'est pas bon et le setAlerts n'est jamais utilisé)

  return (
    <div className="flex flex-col text-sm">
      <p className="text-lg font-semibold text-high-contrast">
        {t("AddReservation.Infos.title")}
      </p>

      <p className="mt-4 font-light text-low-contrast">
        {t("AddReservation.Infos.subTitle")}
      </p>

      {/* {alerts.min_nights && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.min_nights}</p>
            </div>
          </div>
        </div>
      )}
      {alerts.no_checkin && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.no_checkin}</p>
            </div>
          </div>
        </div>
      )}

      {alerts.no_checkout && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.no_checkout}</p>
            </div>
          </div>
        </div>
      )}

      {alerts.max_nights && (
        <div className="flex-1 p-4 mt-2 bg-element-background">
          <div className="flex flex-row gap-3 rounded-6px">
            <ChevronRightIcon width={18} height={18} fill="black" />
            <div className="flex flex-col gap-2">
              <p className="font-bold text-neutral-900">{alerts.max_nights}</p>
            </div>
          </div>
        </div>
      )} */}

      <div className="mt-8">
        <Controller
          control={form.control}
          name="rental"
          rules={{
            required: requiredFields.rental,
            onChange: (e) => {
              form.resetField("dates");
              const adultsActual = form.getValues("adults");
              const childrensActual = form.getValues("children");

              if (rentalsAccess) {
                const index = rentalsAccess.findIndex(
                  (r) => r.id === e.target.value,
                );
                const rental = rentalsAccess[index];

                // setInAndOutTime(rental);
                // setRentalChoiced(rental);

                onSelectDefaultRental(rental);

                console.log("👌 LOG CHECK RESTRICTION", restrictions);

                form.setValue("rental", e.target.value);

                if (adultsActual > rental.adults_max_capacity) {
                  form.setValue("adults", rental.adults_max_capacity);
                }
                if (childrensActual > rental.children_max_capacity) {
                  form.setValue("children", rental.children_max_capacity);
                }
              }
            },
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <div className="max-w-[45rem]">
                <InputSelect
                  required={requiredFields.rental}
                  label={`${t("AddReservation.Infos.rental")}`}
                  items={
                    rentalsAccess?.map((rental) => {
                      return { value: rental.id, label: rental.name };
                    })!
                  }
                  selectedValue={value}
                  onSelect={onChange}
                  disabled={loading}
                  error={form.formState.errors.rental?.message}
                />
              </div>
            );
          }}
        />
      </div>

      <div className="mt-6">
        <Controller
          control={form.control}
          name="type"
          rules={{
            required: requiredFields.type,
          }}
          render={({ field: { onChange, value } }) => (
            <InputSelect
              required={requiredFields.type}
              label={`${t("AddReservation.Infos.type")}`}
              items={[
                { value: 54, label: t("AddReservation.Infos.direct") },
                {
                  value: 69,
                  label: t("AddReservation.Infos.website"),
                },
                {
                  value: 31,
                  label: t("AddReservation.Infos.ical"),
                },
              ]}
              selectedValue={value}
              onSelect={onChange}
              disabled={loading}
              error={form.formState.errors.type?.message}
            />
          )}
        />
      </div>

      <div className="flex items-start gap-4 mt-6">
        <Controller
          control={form.control}
          name="adults"
          rules={{
            required: requiredFields.adults,
          }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              required={requiredFields.adults}
              label={`${t("AddReservation.Infos.adults")}`}
              size="large"
              disabled={loading}
              value={value}
              max={rentalChoiced?.adults_max_capacity ?? 4}
              onChangeText={(adults) => {
                onChange(adults);
              }}
              error={form.formState.errors.adults?.message}
            />
          )}
        />

        <Controller
          control={form.control}
          name="children"
          rules={{
            required: requiredFields.children,
          }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              required={requiredFields.children}
              label={`${t("AddReservation.Infos.children")}`}
              size="large"
              disabled={loading}
              value={value}
              max={rentalChoiced?.children_max_capacity ?? 2}
              onChangeText={onChange}
              error={form.formState.errors.children?.message}
            />
          )}
        />
      </div>

      <div className="mt-6">
        <Controller
          control={form.control}
          name="dates"
          rules={{
            required: requiredFields.dates,
            onChange: (e) => {
              const dates: CalendarInputValueRange = e.target.value;

              if (dates.length !== 2 || dates.includes(null)) {
                form.setError("dates", {
                  type: "manual",
                  message: t("AddReservation.Infos.Error.dates"),
                });
              }
            },
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <CalendarInput
                classNames={{
                  button:
                    "rounded-md bg-white p-2 " + (loading && "bg-gray-100"),
                }}
                required={requiredFields.dates}
                disabled={loading}
                label={`${t("AddReservation.Infos.dates")}`}
                height="full"
                dateType={"range"}
                allowSingleDateInRange={false}
                value={value}
                onChangeStartDateInput={() => {}}
                onChangeEndDateInput={() => {}}
                onChangeDate={onChange}
                isExcludeDate={(date: Date) => {
                  // EN ATTENDANT UNE PR BACKEND - ELYSIO

                  // let isExcluded = false;
                  // const dateFormatCorrect = moment(date).format("YYYY-MM-DD");
                  //
                  // restrictions?.map((restriction: MultipleRestrictionsGet) => {
                  //   if (restriction.rental_id == rentalChoiced?.id) {
                  //     restriction.restrictions.forEach(
                  //       (restriction: Restriction) => {
                  //         if (
                  //           restriction.date == dateFormatCorrect &&
                  //           restriction.no_checkin &&
                  //           restriction.no_checkout
                  //         ) {
                  //           isExcluded = true;
                  //         }
                  //       },
                  //     );
                  //   }
                  // });

                  return false;
                }}
                isCheckinOnly={(date: Date) => {
                  // EN ATTENDANT UNE PR BACKEND - ELYSIO

                  // let isCheckinOnly = false;
                  // const dateFormatCorrect = moment(date).format("YYYY-MM-DD");
                  //
                  // restrictions?.map((restriction: MultipleRestrictionsGet) => {
                  //   if (restriction.rental_id == rentalChoiced?.id) {
                  //     restriction.restrictions.forEach(
                  //       (restriction: Restriction) => {
                  //         if (
                  //           restriction.date == dateFormatCorrect &&
                  //           !restriction.no_checkin &&
                  //           restriction.no_checkout
                  //         ) {
                  //           isCheckinOnly = true;
                  //         }
                  //       },
                  //     );
                  //   }
                  // });

                  return false;
                }}
                isCheckoutOnly={(date: Date) => {
                  // EN ATTENDANT UNE PR BACKEND - ELYSIO

                  // let isCheckoutOnly = false;
                  // const dateFormatCorrect = moment(date).format("YYYY-MM-DD");
                  //
                  // restrictions?.map((restriction: MultipleRestrictionsGet) => {
                  //   if (restriction.rental_id == rentalChoiced?.id) {
                  //     restriction.restrictions.forEach(
                  //       (restriction: Restriction) => {
                  //         if (
                  //           restriction.date == dateFormatCorrect &&
                  //           restriction.no_checkin &&
                  //           !restriction.no_checkout
                  //         ) {
                  //           isCheckoutOnly = true;
                  //         }
                  //       },
                  //     );
                  //   }
                  // });

                  return false;
                }}
                error={form.formState.errors?.dates?.message}
              />
            </>
          )}
        />
      </div>

      <div className="flex mt-6 gap-x-4">
        <div className="flex-1">
          <InputSelect
            register={form.register("checkinTime", {
              required: {
                value: requiredFields.checkinTime,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Infos.checkinTime"),
                }),
              },
              onChange: (e) => form.setValue("checkinTime", e.target.value),
            })}
            required={requiredFields.checkinTime}
            label={t("AddReservation.Infos.checkinTime")}
            items={[
              {
                label: "08:00",
                value: "08:00",
              },
              {
                label: "09:00",
                value: "09:00",
              },
              {
                label: "10:00",
                value: "10:00",
              },
              {
                label: "11:00",
                value: "11:00",
              },
              {
                label: "12:00",
                value: "12:00",
              },
              {
                label: "13:00",
                value: "13:00",
              },
              {
                label: "14:00",
                value: "14:00",
              },
              {
                label: "15:00",
                value: "15:00",
              },
              {
                label: "16:00",
                value: "16:00",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
            ]}
            selectedValue={form.getValues("checkinTime") ?? ""}
            error={form.formState.errors.checkinTime?.message}
            disabled={loading}
          />
        </div>
        <div className="flex-1">
          <InputSelect
            register={form.register("checkoutTime", {
              required: {
                value: requiredFields.checkoutTime,
                message: t("Global.Errors.requiredField", {
                  fieldName: t("AddReservation.Infos.checkoutTime"),
                }),
              },
            })}
            required={requiredFields.checkoutTime}
            label={t("AddReservation.Infos.checkoutTime")}
            items={[
              {
                label: "00:00",
                value: "00:00",
              },
              {
                label: "01:00",
                value: "01:00",
              },
              {
                label: "02:00",
                value: "02:00",
              },
              {
                label: "03:00",
                value: "03:00",
              },
              {
                label: "04:00",
                value: "04:00",
              },
              {
                label: "05:00",
                value: "05:00",
              },
              {
                label: "06:00",
                value: "06:00",
              },
              {
                label: "07:00",
                value: "07:00",
              },
              {
                label: "08:00",
                value: "08:00",
              },
              {
                label: "09:00",
                value: "09:00",
              },
              {
                label: "10:00",
                value: "10:00",
              },
              {
                label: "11:00",
                value: "11:00",
              },
              {
                label: "12:00",
                value: "12:00",
              },
              {
                label: "13:00",
                value: "13:00",
              },
              {
                label: "14:00",
                value: "14:00",
              },
              {
                label: "15:00",
                value: "15:00",
              },
              {
                label: "16:00",
                value: "16:00",
              },
              {
                label: "17:00",
                value: "17:00",
              },
              {
                label: "18:00",
                value: "18:00",
              },
              {
                label: "19:00",
                value: "19:00",
              },
              {
                label: "20:00",
                value: "20:00",
              },
              {
                label: "21:00",
                value: "21:00",
              },
              {
                label: "22:00",
                value: "22:00",
              },
              {
                label: "23:00",
                value: "23:00",
              },
            ]}
            selectedValue={form.getValues("checkoutTime") ?? ""}
            error={form.formState.errors.checkoutTime?.message}
            disabled={loading}
          />
        </div>
      </div>

      <div className="mt-6">
        <ErrorMessage>{error}</ErrorMessage>
      </div>

      <div className="flex gap-4 pb-4 mt-8">
        <Button type="secondary" onClick={onCancel} disabled={loading}>
          {t("Global.cancel")}
        </Button>
        {/* TODO: [AddReservationInfos] You should ideally use form.formState.isValid ⬇️ */}
        <Button
          RightIcon={ArrowRightWhiteIcon}
          disabled={loading || Object.values(form.formState.errors).length > 0}
          loading={loading}
          onClick={form.handleSubmit(onNext)}
        >
          {t("AddReservation.Infos.nextStep")}
        </Button>
      </div>
    </div>
  );
};
