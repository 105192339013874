import React, {SyntheticEvent} from "react";
import {CenterModalProps} from "./CenterModal.type";

import CheckCircleIcon from "../../../assets/icons/check-circle-green.svg?react";
import CloseIcon from "../../../assets/icons/close.svg?react";
import TrashIcon from "../../../assets/icons/trash-active.svg?react";
import {cn} from "../../../helpers/classHelper";

export const CenterModal: React.FC<CenterModalProps> = ({
  children,
  isVisible = false,
  title,
  size = "normal",
  onClose = () => {},
  header,
  CustomHeader = null,
  classNames,
}) => {
  const handleClickStopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  // useEffect(() => {
  //   // Prevents unwanted scrolling while the modal is open
  //   const body: HTMLElement | null = document.querySelector("body");
  //   if (body != null) {
  //     body.style.overflow = isVisible ? "hidden" : "visible";
  //   }
  // }, [isVisible]);

  return (
    <>
      {isVisible && (
        <div
          className={cn(
            "flex flex-row fixed top-0 left-0 w-screen h-screen bg-black/25 items-center justify-center overflow-y-scroll z-[9999]",
            classNames?.divParentModal
          )}
          onClick={onClose}
        >
          <div
            className={cn(
              `${
                size === "normal" ? "w-[400px]" : "w-full max-w-8xl md:w-2/3 lg:w-[64%]"
              } bg-white rounded-16px`,
              classNames?.divModal
            )}
            onClick={handleClickStopPropagation}
          >
            {/* Header */}

            {header === "success" && (
              <div className="relative flex items-center justify-center h-32 bg-element-background-success">
                <CheckCircleIcon width={32} height={32} />
                <div
                  className="absolute cursor-pointer right-3 top-3"
                  onClick={onClose}
                >
                  <CloseIcon width={16} height={16} />
                </div>
              </div>
            )}

            {header === "remove" && (
              <div className="relative flex items-center justify-center h-32 bg-element-background-selection-subtle">
                <TrashIcon width={32} height={32} />
                <div
                  className="absolute cursor-pointer right-3 top-3"
                  onClick={onClose}
                >
                  <CloseIcon width={16} height={16} />
                </div>
              </div>
            )}

            {CustomHeader}

            {title && (
              <div className={cn("flex flex-row p-6 pb-0",classNames?.divLabel)}>
                <p className="flex-1 text-xl font-bold text-high-contrast">
                  {title}
                </p>

                <div className="cursor-pointer" onClick={onClose}>
                  <CloseIcon width={20} height={20} />
                </div>
              </div>
            )}

            {/* Content */}
            <div className={cn("h-auto p-6",classNames?.divContent)}>{children}</div>
          </div>
        </div>
      )}
    </>
  );
};
