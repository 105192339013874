import moment from "moment";
import React, { useEffect, useState } from "react";
import { CalendarLayout } from "../../components/Layout/CalendarLayout/CalendarLayout";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg?react";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg?react";
import { CalendarReservationModal } from "../../components/Calendar/CalendarReservationModal";
import { CalendarListItem } from "../../components/Calendar/ListItem";
import { CalendarListItemSkeleton } from "../../components/Calendar/ListItemSkeleton";
import { Button } from "../../components/Common/Button/Button";
import { CalendarInputValueRange } from "../../components/Common/CalendarInput/CalendarInput.type";
import { DateFilter } from "../../components/Common/DateFilter/DateFilter";
import { useHandleChangeDates } from "../../components/Common/DateFilter/DateFilter.hooks";
import { DateRangePreset } from "../../components/Common/DateFilter/DateFilter.type";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { NoRentalRentalList } from "../../components/RentalList/NoRental/NoRentalRentalList";
import { ReservationStatusEnum } from "../../enums/GETenums";
import { ValueType } from "../../types/commonTypes";
import {
  AutomationItemResponse,
  CalendarReservationResponse,
  TaskAssignationType,
} from "../../types/GETTypes";
import {
  useClickDayGroup,
  useClickReservationCalendarListPage,
  useNextPeriod,
  usePreviousPeriod,
  useReservationsCalendarListPage,
} from "./CalendarListPage.hooks";
import { CalendarDay } from "./CalendarListPage.type";
import { get } from "../../helpers/APIHelper";
import paths from "../../constants/paths";
import { NoReservationList } from "../../components/ReservationList/NoReservation/NoReservationList";
import { ManagementAutomationListAssignModal } from "../../components/ManagementAutomationList/AssignModal";
import { useModal } from "../../hooks/useModal";
import { useTasksCleaning } from "../../hooks/api/automation";

export const CalendarListPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const modalAssign = useModal<{
    reservationId: string;
  }>();

  const [dates, setDates] = useState([
    new Date(),
    moment(new Date()).add(30, "days").toDate(),
  ]);
  const [nbRangeDate, setNbRangeDate] = useState<number>(0);
  const [dateRangePreset, setDateRangePreset] =
    useState<DateRangePreset>("next_seven_days");
  const [days, setDays] = useState<CalendarDay | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [hasCheckinsOrCheckouts, setHasCheckinsOrCheckouts] =
    useState<boolean>(false);
  const [rentals, setRentals] = useState<any[]>([]);

  const [reservationVisible, setReservationVisible] = useState<boolean>(false);
  const [currentReservation, setCurrentReservation] = useState<
    CalendarReservationResponse | undefined
  >();

  const [openedDayGroups, setOpenedDayGroups] = useState<{
    [key: string]: boolean;
  }>({});

  // * Set default all day group to true
  useEffect(() => {
    const newDates = dates as CalendarInputValueRange;

    const dayGroups = Array.from({ length: nbRangeDate }).map((v, index) =>
      moment(newDates[0]).add(index, "days").format("YYYY_MM_DD"),
    );

    let openedDayGroupsData: { [key: string]: boolean } = {};
    dayGroups.forEach((group: string) => {
      openedDayGroupsData[group] = true;
    });

    setOpenedDayGroups(openedDayGroupsData);
  }, [nbRangeDate]);

  const hotUpdateReservationStatus = ({
    reservationId,
    status,
  }: {
    reservationId: ValueType | undefined;
    rentalId: ValueType | undefined;
    status: ReservationStatusEnum;
  }) => {
    // Update reservation in modal
    if (currentReservation) {
      setCurrentReservation({
        ...currentReservation,
        status_reservation: status,
      });
    }

    // Remove reservation in List Calendar
    if (days) {
      const nextDays = JSON.parse(JSON.stringify(days));

      Object.keys(nextDays).forEach((key) => {
        nextDays[key].checkins = (nextDays[key].checkins ?? []).filter(
          (item: any) => item.id !== reservationId,
        );

        nextDays[key].checkouts = (nextDays[key].checkouts ?? []).filter(
          (item: any) => item.id !== reservationId,
        );
      });

      setDays(nextDays);
    }
  };

  const getAllDays = (days: any) => {
    setDays(days);
    const hasCheckins_Checkouts = Object.values(days).some(
      (item: any) => item.checkins.length > 0 || item.checkouts.length > 0,
    );

    setHasCheckinsOrCheckouts(hasCheckins_Checkouts);
  };

  const fetchRentals = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`,
    );

    if (res?.data?.success) {
      setRentals(res?.data?.result?.rentals_access);
    } else {
      setError(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchRentals();
  }, []);

  const fetchDates = async () => {
    useHandleChangeDates(
      dates as CalendarInputValueRange,
      (nbRangeDate: number) => setNbRangeDate(nbRangeDate),
      (days: CalendarDay | undefined) => {
        getAllDays(days);
      },
      (message: string | undefined) => setError(message),
      () => {
        setLoading(true);
        setError(undefined);
      },
      () => setLoading(false),
    );
  };

  useEffect(() => {
    fetchDates();
  }, [dates]);

  const { assign } = useTasksCleaning();

  const handleAssign = async (
    ids: string[],
    type: TaskAssignationType,
    reservationId: string,
  ): Promise<void> => {
    const reservationForCalendarList = await assign(
      reservationId,
      type,
      ids[0],
    );
    await fetchDates();
    await fetchRentals();

    if (currentReservation && currentReservation.cleaning) {
      currentReservation.cleaning = reservationForCalendarList.cleaning;
    }
  };

  const handleClickToday = () => {
    setDates([new Date(), moment(new Date()).add(30, "days").toDate()]);
  };

  const handleAssignError = (): void => {};

  const today = new Date();

  return (
    <>
      <ManagementAutomationListAssignModal
        modal={modalAssign}
        onAssignNotForm={handleAssign}
        onError={handleAssignError}
        isForm={false}
        showHelpDetailsForTeam={false}
        isMultiSelect={false}
      />
      <CalendarReservationModal
        isVisible={reservationVisible}
        reservation={currentReservation}
        needFetchReservation={true}
        onChangeStatusResa={hotUpdateReservationStatus}
        onClose={() => setReservationVisible(false)}
        modalAssign={modalAssign}
        reloadCalendarList={fetchDates}
      />
      {/* TODO : isCheckinPrice in API */}
      <CalendarLayout
        hasRentals={Boolean(rentals.length > 0)}
        navbarActiveItem="list"
        isCheckinPrice={false}
        noRightButtons={
          !loading && !hasCheckinsOrCheckouts && rentals?.length === 0
        }
        displayBy="none"
      >
        <div className="flex flex-col flex-1 gap-3">
          <ErrorMessage>{error}</ErrorMessage>
          <div className="flex items-center justify-between mt-6">
            {!loading && (
              <div className="flex">
                <DateFilter
                  onDatesChanged={(dates, nbRangeDate, days) => {
                    setDates(dates);
                    setNbRangeDate(nbRangeDate);
                    setDays(days);
                  }}
                  onDateRangePresetChanged={(dateRangePreset) => {
                    setDateRangePreset(dateRangePreset);
                  }}
                  onLoading={(loading) => setLoading(loading)}
                  onError={(error) => setError(error)}
                  combineCustomAndDefault={false}
                  customDateRanges={[
                    {
                      label: t("Components.DateFilter.nextSevenDays"),
                      value: "next_seven_days",
                      startDate: () => today,
                      endDate: () => moment(today).add(6, "days").toDate(),
                    },
                    {
                      label: t("Components.DateFilter.lastFourWeeks"),
                      value: "last_four_weeks",
                      startDate: () => moment(today).add(-4, "weeks").toDate(),
                      endDate: () => today,
                    },
                    {
                      label: t("Components.DateFilter.lastTreeMonths"),
                      value: "last_three_months",
                      startDate: () => moment(today).add(-3, "months").toDate(),
                      endDate: () => today,
                    },
                    {
                      label: t("Components.DateFilter.lastTwelveMonths"),
                      value: "last_twelve_months",
                      startDate: () =>
                        moment(today).add(-12, "months").toDate(),
                      endDate: () => today,
                    },
                    {
                      label: t("Components.DateFilter.custom"),
                      value: "custom_range",
                      startDate: () => moment(today).add(-7, "days").toDate(),
                      endDate: () => today,
                    },
                  ]}
                />
              </div>
            )}

            {!loading && dateRangePreset !== "all" && hasCheckinsOrCheckouts ? (
              <div className="flex items-center gap-3">
                <div>
                  <Button
                    LeftIcon={ArrowLeftIcon}
                    type="secondary"
                    onClick={() =>
                      usePreviousPeriod([dates, setDates], dateRangePreset)
                    }
                  >
                    {t("Calendar.previousPeriod")}
                  </Button>
                </div>
                <div>
                  <Button type="secondary" onClick={handleClickToday}>
                    {t("Global.today")}
                  </Button>
                </div>
                <div>
                  <Button
                    RightIcon={ArrowRightIcon}
                    type="secondary"
                    onClick={() =>
                      useNextPeriod([dates, setDates], dateRangePreset)
                    }
                  >
                    {t("Calendar.nextPeriod")}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>

          {/* TODO Améliorer si vraiment pas de données du tout */}

          {(!loading && days && Object.values(days).length === 0) ||
          (!loading && !hasCheckinsOrCheckouts && rentals?.length === 0) ? (
            <div className="flex-1 mt-4">
              <NoRentalRentalList />
            </div>
          ) : null}

          {!loading && !hasCheckinsOrCheckouts && rentals?.length > 0 ? (
            <div className="flex-1 mt-4">
              <NoReservationList />
            </div>
          ) : null}

          {loading || (days && Object.values(days).length > 0) ? (
            <>
              {loading
                ? Array.from({ length: nbRangeDate }).map((v, index) => (
                    <CalendarListItemSkeleton key={index} />
                  ))
                : null}

              {!loading && hasCheckinsOrCheckouts
                ? Array.from({ length: nbRangeDate }).map((value, index) => {
                    let date: Date | undefined = undefined;
                    if (Array.isArray(dates) && dates.length > 0) {
                      date = moment(dates[0]).add(index, "days").toDate();
                      return (
                        <div key={index}>
                          <CalendarListItem
                            date={date}
                            checkins={
                              useReservationsCalendarListPage(date, days)
                                ?.checkins ?? []
                            }
                            checkouts={
                              useReservationsCalendarListPage(date, days)
                                ?.checkouts ?? []
                            }
                            onClick={(
                              reservation: CalendarReservationResponse,
                            ) =>
                              useClickReservationCalendarListPage(
                                reservation,
                                setCurrentReservation,
                                setReservationVisible,
                              )
                            }
                            onClickGroup={(which: string, value: boolean) =>
                              useClickDayGroup(which, value, [
                                openedDayGroups,
                                setOpenedDayGroups,
                              ])
                            }
                          />
                        </div>
                      );
                    } else return null;
                  })
                : null}
            </>
          ) : null}
        </div>
      </CalendarLayout>
    </>
  );
};
