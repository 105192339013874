import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CheckWhiteIcon from "../../../../assets/icons/check-white.svg?react";
import CopyIcon from "../../../../assets/icons/copy-white.svg?react";
import {
  PaymentAccountTypeEnum,
  PaymentReservationStatusEnum,
} from "../../../../enums/GETenums";
import { hiddenDevice } from "../../../../helpers/calendarHelper";
import { getPaymentStatusBadge } from "../../../../helpers/reservationHelper";
import { useReservationById } from "../../../../hooks/api/reservations";
import { useModal } from "../../../../hooks/useModal";
import { ValueType } from "../../../../types/commonTypes";
import {
  PaymentReservationScheduleListItemResponse,
  ReservationResponse,
} from "../../../../types/GETTypes";
import { Button } from "../../../Common/Button/Button";
import { ErrorMessage } from "../../../Common/ErrorMessage/ErrorMessage";
import { RightModal } from "../../../Common/RightModal/RightModal";
import { Separator } from "../../../Common/Separator/Separator";
import { PaymentReservationListInfo } from "..//PaymentReservationListInfo";
import { PaymentReservationSendPaymentModal } from "../PaymentReservationSendPaymentModal";
import { PaymentReservationDoneModal } from "./PaymentReservationDoneModal";
import { PaymentReservationListModalProps } from "./PaymentReservationListModal.type";
import { PaymentReservationListModalMultiInfos } from "./PaymentReservationListModalMultiInfos";
import { PaymentReservationListModalRefund } from "./PaymentReservationListModalRefund";
import { PaymentReservationListModalRemove } from "./PaymentReservationListModalRemove";
import { PaymentReservationListMulti } from "./PaymentReservationListMulti";

export const PaymentReservationListModal: React.FC<
  PaymentReservationListModalProps
> = ({
  loading,
  useMode,
  reservation,
  paymentReservation,
  paymentScheduleId,
  isVisible,
  onBack,
  onClose,
  onTransactionDetailClick,
}) => {
  const { t } = useTranslation();

  const [apiError, setApiError] = useState<string | undefined>();
  const [currentReservation, setCurrentReservation] = useState<
    ReservationResponse | undefined
  >();

  const [paymentReservationDetail, setPaymentReservationDetail] =
    useState<PaymentReservationScheduleListItemResponse>();

  const [paymentLink, setPaymentLink] = useState<string>("");

  useEffect(() => {
    if (paymentReservation && reservation?.id !== paymentReservation?.id) {
      useReservationById(
        paymentReservation?.id,
        (reservationResponse: ReservationResponse | undefined) => {
          setCurrentReservation(reservationResponse);
        },
        (message: string | undefined) => setApiError(message),
        () => {},
        () => {}
      );
    }
  }, [paymentReservation]);

  useEffect(() => {
    if (
      paymentReservation &&
      paymentReservation.payment_schedule?.is_single_payment &&
      paymentReservation.payment_schedule?.payments_schedule_items.length > 0
    ) {
      setPaymentReservationDetail(
        paymentReservation.payment_schedule?.payments_schedule_items[0]
      );
    }
  }, [paymentReservation]);

  useEffect(() => {
    if (
      paymentReservation &&
      paymentReservation.payment_schedule &&
      paymentReservation.payment_schedule.payments_schedule_items.length > 0 &&
      paymentScheduleId
    ) {
      const detail =
        paymentReservation.payment_schedule?.payments_schedule_items.find(
          (item) => item.id === paymentScheduleId
        );
      setPaymentReservationDetail(detail);
    }
  }, [paymentReservation, paymentScheduleId]);

  useEffect(() => {
    if (paymentReservationDetail) {
      setPaymentLink(
        location.protocol +
          "//" +
          location.host +
          "/reservation/" +
          paymentReservation?.id +
          "/payment/" +
          paymentReservationDetail.payment_link
      );
    }
  }, [paymentReservationDetail]);

  // * Remove payment reservation
  const removeModal = useModal<PaymentReservationScheduleListItemResponse>();

  const handleRemovePaymentReservation = (id: ValueType) => {
    console.log("payment reservation to remove : ", id);
    // TODO: [PaymentReservation] Implement endpoint to remove payment reservation for single payment and multiple payments
  };

  // * Refund payment reservation
  const refundModal = useModal<PaymentReservationScheduleListItemResponse>();

  const handleRefundPaymentReservation = (id: ValueType) => {
    console.log("payment reservation to refund : ", id);
    // TODO: [PaymentReservation] Implement endpoint to refund payment reservation for single payment and multiple payments
  };

  // * Send payment reservation
  const [
    isSendPaymentReservationModalVisible,
    setIsSendPaymentReservationModalVisible,
  ] = useState<boolean>(false);

  const handleSendPaymentReservation = (id: number) => {
    console.log("send payment reservation : ", id);
    // TODO: [PaymentReservation] Implement endpoint to send payment reservation for single payment and multiple payments
  };

  const isMultiplePayments = () => {
    return (
      paymentReservation &&
      paymentReservation.payment_schedule &&
      paymentReservation.payment_schedule.payments_schedule_items.length > 1
    );
  };

  // Change payment to done
  const modalChangeToDone = useModal<{
    reservationId: ValueType;
    paymentId: ValueType;
  }>();

  const canPaymentChangeToDone = () => {
    const statusCheck =
      paymentReservationDetail?.payment_status ===
      PaymentReservationStatusEnum.UNPAID;

    const platformCheck =
      reservation?.platform?.name?.toUpperCase() === "DIRECT";

    const wayCheck =
      paymentReservationDetail?.payment_account_type?.toLowerCase() !==
      "stripe";

    return statusCheck && platformCheck && wayCheck;
  };

  // Events
  const handleTransactionDetailClick = (
    paymentReservationId: number,
    paymentSchedule: PaymentReservationScheduleListItemResponse
  ) => {
    setPaymentReservationDetail(paymentSchedule);
    onTransactionDetailClick(paymentReservationId, Number(paymentSchedule.id));
  };

  const getTitle = () => {
    let way = "";
    if (reservation?.platform?.name?.toUpperCase() === "DIRECT") {
      way = t(
        `Global.PaymentAccounts.by_${
          paymentReservationDetail?.payment_account_type ?? ""
        }`
      )?.toLowerCase();
    }

    let platform =
      reservation?.platform?.name?.toUpperCase() === "DIRECT"
        ? t("Payments.PaymentReservationList.direct")
        : t("Payments.PaymentReservationList.platform");

    if (isMultiplePayments() && useMode === "paymentReservation") {
      return `${t("Payments.PaymentReservationList.payment")} ${platform}`;
    } else if (isMultiplePayments() && useMode === "paymentReservationDetail") {
      return `${t(
        "Payments.PaymentReservationList.subPayment"
      )} ${platform} ${way}`;
    } else {
      return `${t(
        "Payments.PaymentReservationList.payment"
      )} ${platform} ${way}`;
    }
  };

  return (
    <>
      <RightModal
        title={<p className="text-xl font-semibold">{getTitle()}</p>}
        rightHeaderNode={
          useMode === "paymentReservation"
            ? getPaymentStatusBadge(
                paymentReservation?.payment_schedule?.payment_status!,
                t
              )
            : getPaymentStatusBadge(
                paymentReservationDetail?.payment_status!,
                t
              )
        }
        isVisible={loading ? false : isVisible}
        onClose={onClose}
        onBack={useMode === "paymentReservationDetail" ? onBack : undefined}
      >
        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col flex-1 overflow-y-auto gap-y-3">
            <ErrorMessage>{apiError}</ErrorMessage>

            {useMode === "paymentReservationDetail" && isMultiplePayments() && (
              <PaymentReservationListModalMultiInfos onBack={onBack} />
            )}

            {/* <PaymentReservationListModalGeneral
              loading={loading}
              paymentReservation={paymentReservation}
            /> */}

            <Separator />

            {/* PAYMENT INFORMATIONS */}
            {(useMode === "paymentReservationDetail" ||
              !isMultiplePayments()) && (
              <div className="flex flex-col gap-y-5">
                <p className="font-bold text-low-contrast">
                  {t("Payments.PaymentReservationList.infosLabel")}
                </p>

                {paymentReservationDetail?.payment_link !== null && (
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <p className="text-low-contrast">
                        {t("Payments.PaymentReservationList.paymentLinkLabel")}
                      </p>
                      {paymentLink ? (
                        <Link
                          to={paymentLink}
                          className="truncate text-active w-72"
                          target="_blank"
                        >
                          {paymentLink}
                        </Link>
                      ) : (
                        <span className="font-semibold">
                          {t("Global.notDefined")}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <Button
                        type="primary"
                        RightIcon={CopyIcon}
                        onClick={() => {
                          navigator.clipboard.writeText(paymentLink);
                        }}
                        disabled={!Boolean(paymentLink)}
                      >
                        {t("Payments.PaymentReservationList.copyPaymentLink")}
                      </Button>
                    </div>
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-start flex-1 space-y-0.5">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.amountLabel")}
                    </p>
                    <p className="text-base font-bold text-high-contrast">
                      {paymentReservationDetail?.price_value
                        ? `${
                            typeof paymentReservationDetail?.price_value ===
                            "number"
                              ? paymentReservationDetail?.price_value.toFixed(2)
                              : paymentReservationDetail?.price_value
                          }${hiddenDevice(
                            paymentReservationDetail?.price_value.toString(),
                            t("Global.currencySymbol")
                          )}`
                        : t("Global.notDefined")}
                    </p>
                  </div>

                  <div className="flex flex-col items-start flex-1">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.paymentWaysLabel")}
                    </p>
                    <p className="text-base font-bold text-high-contrast">
                      {paymentReservationDetail?.payment_account_type ===
                      PaymentAccountTypeEnum.BY_PLATFORM
                        ? reservation?.platform?.name
                        : t(
                            `Global.PaymentAccounts.${
                              paymentReservationDetail?.payment_account_type ??
                              ""
                            }`
                          )}
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex flex-col items-start flex-1 space-y-0.5">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.emailLabel")}
                    </p>

                    <p className="text-base font-semibold text-high-contrast">
                      {paymentReservation?.guest.email
                        ? paymentReservation.guest.email
                        : t("Global.notDefined")}
                    </p>
                  </div>

                  <div className="flex flex-col items-start flex-1">
                    <p className="text-base text-low-contrast">
                      {t("Payments.PaymentReservationList.paidOnLabel")}
                    </p>
                    <p className="text-base font-semibold text-high-contrast">
                      {paymentReservationDetail?.payment_success_date ??
                        t("Global.notDefined")}
                    </p>
                  </div>
                </div>

                {canPaymentChangeToDone() && (
                  <Button
                    RightIcon={CheckWhiteIcon}
                    onClick={() =>
                      modalChangeToDone.open({
                        reservationId: reservation?.id!,
                        paymentId: paymentReservationDetail?.id!,
                      })
                    }
                  >
                    {t("Payments.PaymentReservationList.paymentDone")}
                  </Button>
                )}
              </div>
            )}

            {/* SINGLE OR MULTI PAYMENT ITEMS */}
            {paymentReservation?.payment_schedule &&
              !paymentReservation.payment_schedule.is_single_payment &&
              isMultiplePayments() &&
              useMode === "paymentReservation" && (
                <PaymentReservationListMulti
                  paymentReservation={paymentReservation!}
                  onTransactionDetailClick={handleTransactionDetailClick}
                />
              )}

            {/* PAYMENT DETAILS */}
            {useMode === "paymentReservation" && (
              <>
                <Separator />

                <PaymentReservationListInfo
                  reservation={currentReservation}
                  paymentReservation={paymentReservation!}
                  paymentReservationDetail={paymentReservationDetail!}
                />
              </>
            )}
          </div>
        </div>
      </RightModal>

      {/* REMOVE PAYMENT RESERVATION MODAL */}
      <PaymentReservationListModalRemove
        isVisible={removeModal.isVisible}
        onClose={removeModal.close}
        onRemovePaymentReservation={handleRemovePaymentReservation}
        paymentReservationDetail={paymentReservationDetail}
      />

      {/* REFUND PAYMENT RESERVATION MODAL */}
      <PaymentReservationListModalRefund
        isVisible={refundModal.isVisible}
        onClose={removeModal.close}
        onRefundPaymentReservation={handleRefundPaymentReservation}
        paymentReservationDetail={paymentReservationDetail}
      />

      {/* SEND PAYMENT RESERVATION MODAL */}
      <PaymentReservationSendPaymentModal
        isVisible={isSendPaymentReservationModalVisible}
        paymentReservationDetailId={Number(paymentReservationDetail?.id)}
        onClose={() => setIsSendPaymentReservationModalVisible(false)}
        onSendPayment={handleSendPaymentReservation}
      />

      {/* Set payment is done */}
      <PaymentReservationDoneModal
        data={modalChangeToDone.data}
        isVisible={modalChangeToDone.isVisible}
        onClose={modalChangeToDone.close}
      />
    </>
  );
};
