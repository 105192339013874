import moment from "moment";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "../../assets/icons/chevron-right.svg?react";
import { CalendarInputValueRange } from "../../components/Common/CalendarInput/CalendarInput.type";
import { TableList } from "../../components/Common/TableList/TableList";
import { MainLayout } from "../../components/Layout/MainLayout/MainLayout";
import { PaymentDepositFilters } from "../../components/Payment/PaymentDepositList/Filters/PaymentDepositFilters";
import {
  PaymentDepositFiltersTotals,
  PaymentDepositStatusFilterButton,
} from "../../components/Payment/PaymentDepositList/Filters/PaymentDepositFilters.type";
import { PaymentDepositDetailsModal } from "../../components/Payment/PaymentDepositList/PaymentDepositDetailsModal";
import { PaymentDepositListHeader } from "../../components/Payment/PaymentDepositList/PaymentDepositListHeader";
import { PaymentDepositListItem } from "../../components/Payment/PaymentDepositList/PaymentDepositListItem";
import { PaymentDepositListItemSkeleton } from "../../components/Payment/PaymentDepositList/PaymentDepositListItemSkeleton";
import paths from "../../constants/paths";
import { useModal } from "../../hooks/useModal";
import { UseTableFetchParams, useTablePage } from "../../hooks/useTablePage";
import { PaymentReservationResponse } from "../../types/GETTypes";
import { ValueType } from "../../types/commonTypes";
import { NoPaymentReservation } from "../PaymentReservationList/NoPaymentReservation";

export const PaymentDepositListPage: React.FC<{}> = () => {
  const { t } = useTranslation();

  const depositModal = useModal<PaymentReservationResponse>();

  const tablePage = useTablePage(
    `${import.meta.env.VITE_API_URL}${paths.API.PAYMENTS.DEPOSITS}`,
    "deposit_reservations",
    {
      otherMetas: [
        "total_unpaid",
        "total_overdue",
        "total_pre_authorization",
        "total_payment_refundable",
        "total_canceled",
        "total_filtered",
      ],
    }
  );

  const { currentOtherMetas } = tablePage;

  // * -- PAYMENT RESERVATION BUTTON FILTER --
  const [currentActiveButtonFilter, setCurrentActiveButtonFilter] =
    useState<PaymentDepositStatusFilterButton>("ALL");

  const [currentRangeDate, setCurrentRangeDate] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);

  const [totals, setTotals] = useState<PaymentDepositFiltersTotals>({
    all: 0,
    unpaid: 0,
    overdue: 0,
    pre_authorization: 0,
    payment_refundable: 0,
    canceled: 0,
  });

  const [fetchParams, setFetchParams] = useState<UseTableFetchParams>({});

  const fetchWithFilter = () => {
    if (currentRangeDate === null) {
      setFetchParams({ status: currentActiveButtonFilter });
    } else {
      setFetchParams(() => {
        const nextParams: UseTableFetchParams = {
          status: currentActiveButtonFilter,
          from: moment(currentRangeDate.startDate).format("YYYY-MM-DD"),
          to: moment(currentRangeDate.endDate).format("YYYY-MM-DD"),
        };

        if (currentActiveButtonFilter === "ALL") {
          delete nextParams.status;
        }

        return nextParams;
      });
    }
  };

  useEffect(() => {
    fetchWithFilter();
  }, [currentActiveButtonFilter, currentRangeDate]);

  useEffect(() => {
    if (
      currentActiveButtonFilter === "ALL" &&
      currentRangeDate !== null &&
      currentOtherMetas.length > 0
    ) {
      setTotals({
        all:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_filtered")
              ?.value
          ) ?? 0,
        unpaid:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_unpaid")?.value
          ) ?? 0,
        pre_authorization:
          Number(
            currentOtherMetas.find(
              (meta) => meta.key === "total_pre_authorization"
            )?.value
          ) ?? 0,
        payment_refundable:
          Number(
            currentOtherMetas.find(
              (meta) => meta.key === "total_payment_refundable"
            )?.value
          ) ?? 0,
        overdue:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_overdue")
              ?.value
          ) ?? 0,
        canceled:
          Number(
            currentOtherMetas.find((meta) => meta.key === "total_canceled")
              ?.value
          ) ?? 0,
      });
    }
  }, [currentActiveButtonFilter, currentRangeDate, currentOtherMetas]);

  useEffect(() => {
    if (Object.keys(fetchParams).length > 0) {
      tablePage.fetch(fetchParams);
    }
  }, [fetchParams]);

  const handleDatesChange = (dates: CalendarInputValueRange) => {
    if (Array.isArray(dates) && dates.length >= 2) {
      const startDate: Date | null = dates[0];
      const endDate: Date | null = dates[1];
      if (
        (startDate === null && endDate === null) ||
        (startDate instanceof Date && endDate instanceof Date)
      ) {
        if (startDate !== null && endDate !== null) {
          setCurrentRangeDate({
            startDate,
            endDate,
          });

          let fetchParams: UseTableFetchParams = {
            from: moment(startDate).format("YYYY-MM-DD"),
            to: moment(endDate).format("YYYY-MM-DD"),
          };

          if (currentActiveButtonFilter !== "ALL") {
            fetchParams = {
              ...fetchParams,
              status: currentActiveButtonFilter,
            };
          }

          tablePage.fetch(fetchParams);
        }
      }
    }
  };

  const getTitle = () => {
    return (
      <p className="flex flex-row items-center gap-1">
        {t("Payments.title")} <ChevronRightIcon />
        {t("Payments.depositList")}
      </p>
    );
  };

  const getFiltersNode = (): ReactNode => {
    return (
      <PaymentDepositFilters
        loading={tablePage.loading}
        onSearchText={tablePage.handleChangeSearch}
        totals={totals}
        currentActiveButtonFilter={currentActiveButtonFilter}
        onFilterButtonClick={(value) =>
          setCurrentActiveButtonFilter(
            value as PaymentDepositStatusFilterButton
          )
        }
        onDatesChanged={handleDatesChange}
      />
    );
  };

  const handleClickItem = (id: ValueType) => {
    const dataIndex = tablePage.data?.findIndex((d: any) => d.id === id);
    if (dataIndex === -1) return;

    depositModal.open(tablePage.data[dataIndex]);
  };

  return (
    <>
      <PaymentDepositDetailsModal
        paymentReservation={depositModal.data!}
        isVisible={depositModal.isVisible}
        onAskRefresh={fetchWithFilter}
        onClose={depositModal.close}
      />

      <MainLayout
        title={getTitle()}
        sidebarActiveItem="payments"
        sidebarActiveSubItem="deposits"
      >
        <TableList
          i18nElement="Global.deposit"
          Filters={getFiltersNode()}
          filterTotal={
            Number(
              currentOtherMetas.find((meta) => meta.key === "total_filtered")
                ?.value
            ) ?? 0
          }
          Header={PaymentDepositListHeader}
          tablePage={tablePage}
          Item={PaymentDepositListItem}
          Skeleton={PaymentDepositListItemSkeleton}
          NoData={
            <NoPaymentReservation
              title={t("Global.noCautionTitle")}
              description={t("Global.noCautionContent")}
            />
          }
          onClick={handleClickItem}
        />
      </MainLayout>
    </>
  );
};
