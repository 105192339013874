import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlatformEnum } from "../../../enums/GETenums";
import { hiddenDevice } from "../../../helpers/calendarHelper";
import {
  PaymentReservationResponse,
  PaymentReservationScheduleListItemResponse,
  ReservationResponse,
} from "../../../types/GETTypes";
import { Separator } from "../../Common/Separator/Separator";

type PaymentReservationListInfoProps = {
  reservation: ReservationResponse | undefined;
  paymentReservation: PaymentReservationResponse;
  paymentReservationDetail: PaymentReservationScheduleListItemResponse;
};

export const PaymentReservationListInfo: React.FC<
  PaymentReservationListInfoProps
> = ({ reservation, paymentReservation, paymentReservationDetail }) => {
  const { t } = useTranslation();

  const [newPaymentReservationDetail, setNewPaymentReservationDetail] =
    useState<PaymentReservationScheduleListItemResponse | undefined>(undefined);

  const [paymentLink, setPaymentLink] = useState<string>("");

  useEffect(() => {
    // * We obtain the payment schedule for single payment
    if (
      paymentReservationDetail === undefined &&
      paymentReservation?.payment_schedule?.payments_schedule_items.length === 1
    ) {
      setNewPaymentReservationDetail(
        paymentReservation.payment_schedule.payments_schedule_items[0]
      );
    } else {
      setNewPaymentReservationDetail(paymentReservationDetail);
    }
  }, [paymentReservation, paymentReservationDetail]);

  useEffect(() => {
    if (newPaymentReservationDetail) {
      setPaymentLink(
        location.protocol +
          "//" +
          location.host +
          "/reservation/" +
          paymentReservation?.id +
          "/payment/" +
          newPaymentReservationDetail.payment_link
      );
    }
  }, [newPaymentReservationDetail]);

  return (
    <>
      {paymentReservation?.platform ? (
        <>
          {paymentReservation?.platform.id === PlatformEnum.AIRBNB ? (
            <div className="flex flex-col gap-y-5">
              {/** GUEST PAYMENT DETAILS  */}
              <div className="flex flex-col gap-y-4">
                <p className="font-semibold leading-4 text-low-contrast">
                  {t(
                    "Payments.PaymentReservationList.AirbnbInfo.paymentDetailsTitle"
                  )}
                </p>

                <div className="flex flex-col gap-y-2">
                  {reservation?.payment_details_guest !== undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {reservation?.payment_details_guest.night.label}
                      </p>
                      <p className="font-bold text-high-contrast shrink-0">
                        {`${
                          typeof reservation?.payment_details_guest.night
                            ?.value === "number"
                            ? Number(
                                reservation?.payment_details_guest.night?.value
                              ).toFixed(2)
                            : reservation?.payment_details_guest.night?.value
                        } ${hiddenDevice(
                          reservation?.payment_details_guest.night?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {reservation?.payment_details_guest.cleaning_fee !==
                    undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {reservation?.payment_details_guest.cleaning_fee.label}
                      </p>
                      <p className="font-bold text-high-contrast">
                        {`${
                          typeof reservation?.payment_details_guest.cleaning_fee
                            ?.value === "number"
                            ? Number(
                                reservation?.payment_details_guest.cleaning_fee
                                  ?.value
                              ).toFixed(2)
                            : reservation?.payment_details_guest.cleaning_fee
                                ?.value
                        } ${hiddenDevice(
                          reservation?.payment_details_guest.cleaning_fee?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {reservation?.payment_details_guest.guest_service_fees !==
                    undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {
                          reservation?.payment_details_guest.guest_service_fees
                            ?.label
                        }
                      </p>
                      <p className="font-bold text-high-contrast">
                        {`${
                          typeof reservation?.payment_details_guest
                            .guest_service_fees?.value === "number"
                            ? Number(
                                reservation?.payment_details_guest
                                  .guest_service_fees?.value
                              ).toFixed(2)
                            : reservation?.payment_details_guest
                                .guest_service_fees?.value
                        } ${hiddenDevice(
                          reservation?.payment_details_guest.guest_service_fees?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {reservation?.payment_details_guest.city_tax !==
                    undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {reservation?.payment_details_guest.city_tax.label}
                      </p>
                      <p className="font-bold text-high-contrast">
                        {`${
                          typeof reservation?.payment_details_guest.city_tax
                            ?.value === "number"
                            ? Number(
                                reservation?.payment_details_guest.city_tax
                                  ?.value
                              ).toFixed(2)
                            : reservation?.payment_details_guest.city_tax?.value
                        } ${hiddenDevice(
                          reservation?.payment_details_guest.city_tax?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}
                </div>

                {reservation?.payment_details_guest.total !== undefined && (
                  <div className="flex justify-between">
                    <p className="font-bold text-high-contrast">
                      {reservation?.payment_details_guest.total.label}
                    </p>
                    <p className="font-bold text-high-contrast">
                      {`${
                        typeof reservation?.payment_details_guest.total
                          .value === "number"
                          ? Number(
                              reservation?.payment_details_guest.total.value
                            ).toFixed(2)
                          : reservation?.payment_details_guest.total.value
                      } ${hiddenDevice(
                        reservation?.payment_details_guest.total.value.toString(),
                        t("Global.currencySymbol")
                      )}`}
                    </p>
                  </div>
                )}
              </div>

              <Separator />

              {/** HOST PAYMENT DETAILS  */}
              <div className="flex flex-col gap-y-4">
                <p className="font-semibold leading-4 text-low-contrast">
                  {t(
                    "Payments.PaymentReservationList.AirbnbInfo.hostTransferTitle"
                  )}
                </p>

                <div className="flex flex-col gap-y-2">
                  {reservation?.payment_details_host?.night !== undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {reservation?.payment_details_host.night.label}
                      </p>
                      <p className="font-bold text-high-contrast shrink-0">
                        {`${
                          typeof reservation?.payment_details_host.cleaning_fee
                            ?.value === "number"
                            ? Number(
                                reservation?.payment_details_host.night?.value
                              ).toFixed(2)
                            : reservation?.payment_details_host.night?.value
                        }${hiddenDevice(
                          reservation?.payment_details_host.night?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {reservation?.payment_details_host?.cleaning_fee !==
                    undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {reservation?.payment_details_host.cleaning_fee.label}
                      </p>
                      <p className="font-bold text-high-contrast shrink-0">
                        {`${
                          typeof reservation?.payment_details_host.cleaning_fee
                            ?.value === "number"
                            ? Number(
                                reservation?.payment_details_host.cleaning_fee
                                  ?.value
                              ).toFixed(2)
                            : reservation?.payment_details_host.cleaning_fee
                                ?.value
                        }${hiddenDevice(
                          reservation?.payment_details_host.cleaning_fee?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {reservation?.payment_details_host?.host_service_fees !==
                    undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {
                          reservation?.payment_details_host.host_service_fees
                            .label
                        }
                      </p>

                      <p className="font-bold text-high-contrast shrink-0">
                        {`${
                          typeof reservation?.payment_details_host
                            .host_service_fees?.value === "number"
                            ? Number(
                                reservation?.payment_details_host
                                  .host_service_fees?.value
                              ).toFixed(2)
                            : reservation?.payment_details_host
                                .host_service_fees?.value
                        }${hiddenDevice(
                          reservation?.payment_details_host.host_service_fees?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}
                </div>

                {reservation?.payment_details_host?.total !== undefined && (
                  <div className="flex justify-between">
                    <p className="font-bold text-high-contrast">
                      {reservation?.payment_details_host.total.label}
                    </p>
                    <p className="font-bold text-high-contrast">
                      {`${
                        typeof reservation?.payment_details_host.total.value ===
                        "number"
                          ? Number(
                              reservation?.payment_details_host.total.value
                            ).toFixed(2)
                          : reservation?.payment_details_host.total.value
                      }${hiddenDevice(
                        reservation?.payment_details_host.total.value.toString(),
                        t("Global.currencySymbol")
                      )}`}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {paymentReservation?.platform.id === PlatformEnum.BOOKING ? (
            <div className="flex flex-col gap-y-5">
              {/** GUEST PAYMENT DETAILS  */}
              <div className="flex flex-col gap-y-4">
                <p className="font-semibold leading-4 text-low-contrast">
                  {t(
                    "Payments.PaymentReservationList.BookingInfo.paymentDetailsTitle"
                  )}
                </p>

                <div className="flex flex-col gap-y-2">
                  {/* nights details */}
                  {reservation?.payment_details_guest.nights !== undefined &&
                    reservation?.payment_details_guest.nights.map((night) => (
                      <div className="flex justify-between">
                        <p className="font-normal text-low-contrast">
                          {night.label}
                        </p>
                        <p className="font-bold text-high-contrast shrink-0">
                          {`${Number(night.value)?.toFixed(2)} ${t(
                            "Global.currencySymbol"
                          )}`}
                        </p>
                      </div>
                    ))}

                  {/* nights details subtotal */}
                  {reservation?.payment_details_guest.subtotal !==
                    undefined && (
                    <div className="flex justify-between">
                      <p className="font-bold text-high-contrast">
                        {reservation?.payment_details_guest.subtotal.label}
                      </p>
                      <p className="font-bold text-high-contrast">
                        {`${
                          typeof reservation?.payment_details_guest.subtotal
                            .value === "number"
                            ? Number(
                                reservation?.payment_details_guest.subtotal
                                  .value
                              ).toFixed(2)
                            : reservation?.payment_details_guest.subtotal.value
                        } ${hiddenDevice(
                          reservation?.payment_details_guest.subtotal.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {/* taxes and fees details */}
                  {reservation?.payment_details_guest.taxes_and_fees !==
                    undefined &&
                    reservation?.payment_details_guest.taxes_and_fees.map(
                      (taxAndFee) => (
                        <div className="flex justify-between">
                          <p className="font-normal text-low-contrast">
                            {taxAndFee.label}
                          </p>
                          <p className="font-bold text-high-contrast shrink-0">
                            {`${Number(taxAndFee.value)?.toFixed(2)} ${t(
                              "Global.currencySymbol"
                            )}`}
                          </p>
                        </div>
                      )
                    )}
                </div>

                {/* total for guest */}
                {reservation?.payment_details_guest.total !== undefined && (
                  <div className="flex justify-between">
                    <p className="font-bold text-high-contrast">
                      {reservation?.payment_details_guest.total.label}
                    </p>
                    <p className="font-bold text-high-contrast">
                      {`${
                        typeof reservation?.payment_details_guest.total
                          .value === "number"
                          ? Number(
                              reservation?.payment_details_guest.total.value
                            ).toFixed(2)
                          : reservation?.payment_details_guest.total.value
                      } ${hiddenDevice(
                        reservation?.payment_details_guest.total.value.toString(),
                        t("Global.currencySymbol")
                      )}`}
                    </p>
                  </div>
                )}

                {reservation?.payment_details_guest.vat !== undefined && (
                  <div className="flex justify-center">
                    <p className="text-low-contrast">
                      {reservation?.payment_details_guest.vat.label}
                    </p>
                  </div>
                )}
              </div>

              <Separator />

              {/** HOST PAYMENT DETAILS  */}
              <div className="flex flex-col gap-y-4">
                <p className="font-semibold leading-4 text-low-contrast">
                  {t(
                    "Payments.PaymentReservationList.BookingInfo.hostTransferTitle"
                  )}
                </p>

                <div className="flex flex-col gap-y-2">
                  {/* booking commission */}
                  {reservation?.payment_details_host
                    ?.host_platform_commission !== undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {
                          reservation?.payment_details_host
                            .host_platform_commission.label
                        }
                      </p>
                      <p className="font-bold text-high-contrast shrink-0">
                        {`${
                          typeof reservation?.payment_details_host
                            .host_platform_commission?.value === "number"
                            ? Number(
                                reservation?.payment_details_host
                                  .host_platform_commission?.value
                              ).toFixed(2)
                            : reservation?.payment_details_host
                                .host_platform_commission?.value
                        } ${hiddenDevice(
                          reservation?.payment_details_host.host_platform_commission?.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {/* transaction fee */}
                  {reservation?.payment_details_host
                    ?.host_platform_transaction_fees !== undefined && (
                    <div className="flex justify-between">
                      <p className="font-normal text-low-contrast">
                        {
                          reservation?.payment_details_host
                            .host_platform_transaction_fees.label
                        }
                      </p>

                      <p className="font-bold text-high-contrast shrink-0">
                        {`${
                          typeof reservation?.payment_details_host
                            .host_platform_transaction_fees.value === "number"
                            ? Number(
                                reservation?.payment_details_host
                                  .host_platform_transaction_fees.value
                              ).toFixed(2)
                            : reservation?.payment_details_host
                                .host_platform_transaction_fees.value
                        } ${hiddenDevice(
                          reservation?.payment_details_host.host_platform_transaction_fees.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}

                  {/* total for host */}
                  {reservation?.payment_details_host.total !== undefined && (
                    <div className="flex justify-between">
                      <p className="font-bold text-high-contrast">
                        {reservation?.payment_details_host.total.label}
                      </p>
                      <p className="font-bold text-high-contrast">
                        {`${
                          typeof reservation?.payment_details_host.total
                            .value === "number"
                            ? Number(
                                reservation?.payment_details_host.total.value
                              ).toFixed(2)
                            : reservation?.payment_details_host.total.value
                        } ${hiddenDevice(
                          reservation?.payment_details_host.total.value.toString(),
                          t("Global.currencySymbol")
                        )}`}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};
