import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import WWWIcon from "../../../assets/icons/www.svg?react";
import CheckIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import { Card } from "../../Common/Card/Card";
import { WebsiteResponse } from "../../../types/GETTypes";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../Common/TextInput/TextInput";
import { ErrorMessage } from "../../Common/ErrorMessage/ErrorMessage";
import { WebsiteURL } from "../WebsiteURL";
import { post } from "../../../helpers/APIHelper";
import paths from "../../../constants/paths";

export const WebsiteURLCard: React.FC<{
  website: WebsiteResponse | undefined;
  onUpdateWebsite: (nextWebsite: WebsiteResponse) => void;
}> = ({ website, onUpdateWebsite }) => {
  const { t } = useTranslation();

  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<string | undefined>();

  const [editMode, setEditMode] = useState<boolean>(false);

  const editModeRef = useRef<{
    handleClear: () => void;
    handleCheckData: () => {
      URL: string;
      customURL: string | undefined;
    } | null;
  } | null>(null);

  const handleCancel = () => {
    setEditMode(false);
    editModeRef.current?.handleClear();
  };

  const handleUpdate = async () => {
    const values = editModeRef.current?.handleCheckData();

    if (!values) return;

    setUpdateLoading(true);
    setUpdateError(undefined);

    const formData = new FormData();
    formData.append("url", values.customURL ?? values.URL);
    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.WEBSITE.UPDATE.URL(
        website?.id!
      )}`,
      formData
    );

    if (!res?.data?.success) {
      setUpdateError(res?.response?.data?.message);
    } else {
      onUpdateWebsite(res.data?.result);
      setEditMode(false);
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    setUpdateError(undefined);
  }, [editMode]);

  return (
    <Card
      Icon={WWWIcon}
      label={t("Website.URL.title")}
      anchor="url-card"
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode ? () => handleUpdate() : () => setEditMode(!editMode),
        disabled: !website || updateLoading,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: handleCancel,
              disabled: updateLoading,
            }
          : undefined
      }
    >
      <ErrorMessage>{updateError}</ErrorMessage>

      {editMode ? (
        <EditMode ref={editModeRef} loading={updateLoading} website={website} />
      ) : (
        <ViewMode website={website} />
      )}
    </Card>
  );
};

const ViewMode: React.FC<{ website: WebsiteResponse | undefined }> = ({
  website,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2">
      <TextInput
        label={t("Website.URL.label")}
        value={
          website?.integration.url ?? window.location.origin + "/website/sample"
        }
        disabled={!website}
        type="clipboard"
      />
    </div>
  );
};

interface EditModeProps {
  website: WebsiteResponse | undefined;
  loading: boolean;
}

const EditMode = forwardRef<
  {
    handleClear: () => void;
    handleCheckData: () => {
      URL: string;
      customURL: string | undefined;
    } | null;
  },
  EditModeProps
>(({ website, loading }, ref) => {
  const prefix = window.location.origin + "/website/";

  const [currentURL, setCurrentURL] = useState<string>("");
  const [currentCustomURL, setCurrentCustomURL] = useState<
    string | undefined
  >();

  const handleClear = () => {
    setCurrentURL("");
    setCurrentCustomURL(undefined);
  };

  const handleCheckData = () => {
    return {
      URL: currentURL,
      customURL: currentCustomURL,
    };
  };

  useImperativeHandle(ref, () => ({
    handleClear,
    handleCheckData,
  }));

  useEffect(() => {
    if (website) {
      if (website.integration.url.startsWith(prefix)) {
        setCurrentURL(website.integration.url);
        setCurrentCustomURL(undefined);
      } else {
        setCurrentURL(prefix);
        setCurrentCustomURL(website.integration.url);
      }
    }
  }, [website]);

  return (
    <div className="flex flex-col gap-3">
      <WebsiteURL
        URL={currentURL}
        URLError={undefined}
        customURL={currentCustomURL}
        onChangeURL={setCurrentURL}
        onChangeCustomURL={setCurrentCustomURL}
      />
    </div>
  );
});
