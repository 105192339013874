import { t } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ArrowLeftIcon from "../../../../assets/icons/arrow-left.svg?react";
import CheckIcon from "../../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../../assets/icons/edit.svg?react";
import MoneyIcon from "../../../../assets/icons/money.svg?react";
import { getCurrentCanEdit } from "../../../../helpers/workspaceHelper";
import useUserStore from "../../../../stores/useUserStore";
import { RequiredFields } from "../../../../types/commonTypes";
import { Card } from "../../../Common/Card/Card";
import { WorkInProgress } from "../../../WorkInProgress/WorkInProgress";
import {
  RentalWebsitePaymentAndReceiptCardForm,
  RentalWebsitePaymentAndReceiptCardProps,
} from "./PaymentAndReceipt.type";

export const RentalWebsitePaymentAndReceiptCard: React.FC<
  RentalWebsitePaymentAndReceiptCardProps
> = ({ loading, rentalId, rentalPayments, workspaceOwner }) => {
  const userStore = useUserStore();
  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);

  const [editMode, setEditMode] = useState<boolean>(false);

  const form = useForm<RentalWebsitePaymentAndReceiptCardForm>({
    mode: "all",
    defaultValues: {
      nightReceipt: null,
      depositReceipt: null,
      extraReceipt: null,
    },
  });

  const requiredFields: RequiredFields<RentalWebsitePaymentAndReceiptCardForm> =
    {
      nightReceipt: true,
      depositReceipt: true,
      extraReceipt: true,
    };

  const isEditButtonDisabled = () => {
    if (!getCurrentCanEdit({ user: userStore.user, workspaceOwner }))
      return true;

    return loadingValidation;
  };

  const handlePaymentAndReceiptEdit = () => {};

  if (loading) return <p>Loading ...</p>;

  return (
    <Card
      Icon={MoneyIcon}
      label={t("Rental.Website.PaymentAndReceipt.title")}
      anchor="rate-card"
      loading={loadingValidation}
      button={{
        Icon: editMode ? CheckIcon : EditIcon,
        type: editMode ? "primary" : "secondary",
        label: editMode ? t("Global.record") : t("Global.edit"),
        onClick: editMode
          ? form.handleSubmit(handlePaymentAndReceiptEdit)
          : () => setEditMode(!editMode),
        // disabled: isEditButtonDisabled(),
        disabled: true,
      }}
      secondaryButton={
        editMode
          ? {
              label: t("Global.cancel"),
              LeftIcon: ArrowLeftIcon,
              onClick: () => setEditMode(false),
            }
          : undefined
      }
    >
      {/* TODO: A connecter au back */}
      <WorkInProgress />
      {/* <div className="flex flex-col gap-y-4">
        <div className="text-center">
          <Trans
            i18nKey="Rental.Website.PaymentAndReceipt.infos"
            components={{
              strong: <span className="font-semibold"></span>,
            }}
          />
        </div>
        {editMode ? (
          <>
            <div>
              <InputSelect
                label={t("Rental.Website.PaymentAndReceipt.nightReceiptLabel")}
                items={[]}
                selectedValue={""}
                required={requiredFields.nightReceipt}
              />
              <p className="mt-2 text-xs text-low-contrast">
                {t("Rental.Website.PaymentAndReceipt.fieldInfo")}
              </p>
            </div>
            <div>
              <InputSelect
                label={t(
                  "Rental.Website.PaymentAndReceipt.depositReceiptLabel"
                )}
                items={[]}
                selectedValue={""}
                required={requiredFields.depositReceipt}
              />
              <p className="mt-2 text-xs text-low-contrast">
                {t("Rental.Website.PaymentAndReceipt.fieldInfo")}
              </p>
            </div>
            <div>
              <InputSelect
                label={t("Rental.Website.PaymentAndReceipt.extrasReceiptLabel")}
                items={[]}
                selectedValue={""}
                required={requiredFields.extraReceipt}
              />
              <p className="mt-2 text-xs text-low-contrast">
                {t("Rental.Website.PaymentAndReceipt.fieldInfo")}
              </p>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-between gap-x-2">
            <div className="flex flex-col space-y-2">
              <p className="text-base font-semibold text-high-contrast">
                Compte Stripe pour encaisser les nuitées
              </p>
              <p className="text-low-contrast">Via Stripe</p>
            </div>

            <p className="text-base font-semibold text-high-contrast">
              Compte Stripe 0878987
            </p>
          </div>
        )}
      </div> */}
    </Card>
  );
};
