import {ValueType} from "../../types/commonTypes";

export type AutoMessageUpsertGeneralForm = {
  trigger: string;
  delay: string;
  conditions: string[];
};

type AutoMessageUpsertChannelsItemForm = {
  active: boolean;
  value: "email" | "SMS" | "platform";
};

export type AutoMessageUpsertChannelsForm = {
  Airbnb: AutoMessageUpsertChannelsItemForm;
  Booking: AutoMessageUpsertChannelsItemForm;
  Direct: AutoMessageUpsertChannelsItemForm;
};

type AutoMessageUpsertContentItemForm = {
  title: string;
  message: string;
};

export type AutoMessageUpsertContentsForm = {
  french: AutoMessageUpsertContentItemForm | null;
  english: AutoMessageUpsertContentItemForm | null;
  spanish: AutoMessageUpsertContentItemForm | null;
  german: AutoMessageUpsertContentItemForm | null;
  isAllRentals: boolean;
  rentalsId: ValueType[];
  name: string;
};
export enum AutoMessageLanguageEnum {
  french = 1,
  portugish,
  spanish,
  english,
  german,
}
