import { PlatformResponse, TaskStatus } from "../../types/GETTypes";

export type BookingPageProps = {};

export enum PLATFORM_RESERVATION {
  AIRBNB = "AIRBNB",
  BOOKING = "BOOKING",
  DIRECT = "DIRECT",
  EXTERNAL = "EXTERNAL",
}

// TODO: [BookingPage.type] REMOVE THESE ENUM AND MOVE INTO GETenums file ⬇️
export enum RESERVATION_STATUS {
  NONE = -1,
  REQUEST = 0,
  CONFIRMED_FINISHED = 1,
  CONFIRMED_PROGRESS = 2,
  CONFIRMED_COMING = 3,
  CANCELED = 4,
  CANCELED_BY_TRAVELER = 5,
  PRE_APPROVED = 6,
}

export enum CONTRACT_STATUS {
  KO = 0,
  OK = 1,
}

export type ReservationForCalendarList = {
  id: number;
  reservation_date: string;
  checkin: string;
  checkout: string;
  time_in: string;
  time_out: string;
  adults_count: number;
  nights_count: number;
  children_count: number;
  background_color: string;
  text_color: string;
  guest: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    photo: string;
  };
  platform: PlatformResponse;
  rental: {
    name: string;
    address: string;
    postal_code: string;
    city: string;
    country: string;
    photo: string;
  };
  cleaning: {
    status: TaskStatus;
    photo: string;
    name: string;
  };
};

export type Payments = {
  id: number;
  reservation_id: number;
  platform_id: number;
  status: number;
  currency_id: number;
  amount: number;
  type: string;
  label: string;
  created_at: string;
};

export type JournalEnum =
  | "reservation_update_dates"
  | "reservation_update_guest_name"
  | "reservation_update_guest_phone"
  | "reservation_update_guest_email"
  | "reservation_update_guest_address"
  | "reservation_update_guests_total"
  | "reservation_update_notes"
  | "reservation_update_night_price"
  | "reservation_generation_lock_code"
  | "reservation_message_sent"
  | "reservation_new";

export type Journal = {
  date: string;
  title: string;
  description: string;
  type: JournalEnum;
};
