import React, {useEffect, useState} from "react";
import {UseFormGetValues} from "react-hook-form";
import {useTranslation} from "react-i18next";
import ArrowLeftIcon from "../../../assets/icons/arrow-left.svg?react";
import CalendarIcon from "../../../assets/icons/calendar.svg?react";
import CheckWhiteIcon from "../../../assets/icons/check-white.svg?react";
import EditIcon from "../../../assets/icons/edit.svg?react";
import InputCrossIcon from "../../../assets/icons/input-cross.svg?react";
import MoneyBagIcon from "../../../assets/icons/money-bag.svg?react";
import {PaymentAccountTypeEnum} from "../../../enums/GETenums";
import {getFormattedPrice} from "../../../helpers/currencyHelper";
import {capitalizeFirstLetter} from "../../../helpers/stringHelper";
import {UseModal} from "../../../hooks/useModal";
import {
  NotificationStatusesListItemResponse,
  PaymentReservationScheduleResponse,
} from "../../../types/GETTypes";
import {Button} from "../../Common/Button/Button";
import {ErrorMessage} from "../../Common/ErrorMessage/ErrorMessage";
import {
  PaymentPolicyDepositPaymentOptionEnum,
  PaymentPolicyForm,
  PaymentPolicyItem,
  PaymentPolicyPaymentTriggerEnum,
  PaymentPolicyResponse,
} from "../../Payment/Payment.type";
import {useAddReservationNextStep} from "./AddReservationPayment.hooks";
import {displayDepositPaymentMethod} from "../../../helpers/paymentHelper";
import { AddReservationType } from "../AddReservation.type";
import { useCheckAuth } from "../../../hooks/useCheckAuth";
import moment from "moment/moment";

export const AddReservationPayment: React.FC<{
  modal: UseModal<{}>;
  submitError: string;
  paymentPolicyFormValues: UseFormGetValues<PaymentPolicyForm>;
  reservation: AddReservationType;
  onCancel: () => void;
  onNext: (
    reservation_id: number,
    payment_schedule: PaymentReservationScheduleResponse,
    notification_statuses: NotificationStatusesListItemResponse[],
  ) => void;
  onBack: () => void;
  currentPolicy: PaymentPolicyResponse | undefined;
}> = ({
  modal,
  submitError,
  paymentPolicyFormValues,
  reservation,
  onCancel,
  onNext,
  onBack,
  currentPolicy,
}) => {
  const { t } = useTranslation();
  const { user } = useCheckAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const getDaysDelay = (payment: PaymentPolicyItem): number => {
    switch (payment.trigger) {
      case PaymentPolicyPaymentTriggerEnum.AT_RESERVATION:
        return 0;
      case PaymentPolicyPaymentTriggerEnum.AT_CHECKIN:
        return 0;
      case PaymentPolicyPaymentTriggerEnum.FIVE_DAYS_BEFORE_ARRIVAL:
        return 5;
      case PaymentPolicyPaymentTriggerEnum.SEVEN_DAYS_BEFORE_ARRIVAL:
        return 7;
      case PaymentPolicyPaymentTriggerEnum.FOURTEEN_DAYS_BEFORE_ARRIVAL:
        return 40;
      case PaymentPolicyPaymentTriggerEnum.TWENTY_FOUR_HOURS_BEFORE_ARRIVAL:
        return 1;
      case PaymentPolicyPaymentTriggerEnum.SIXTY_DAYS_BEFORE_ARRIVAL:
        return 60;
      case PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE:
        return moment(payment.specific_date).diff(
          moment(reservation.general?.checkin),
        );
    }

    return 0;
  };

  const hasAutomaticLinkPayment = (): boolean => {
    return true;
  };

  const getNbTimesPayment = (length: number): string => {
    let string = t("AddReservation.Payment.NbTimes.one");

    if (length === 1) {
      string = t("AddReservation.Payment.NbTimes.one");
    } else if (length === 2) {
      string = t("AddReservation.Payment.NbTimes.two");
    } else if (length === 3) {
      string = t("AddReservation.Payment.NbTimes.three");
    }

    return string;
  };

  const handleNext = (values: PaymentPolicyForm) => {
    useAddReservationNextStep({
      reservation,
      values,
      onStart: () => {
        setError(undefined);
        setLoading(true);
      },
      onEnd: (
        reservationId: number,
        payment_schedule: PaymentReservationScheduleResponse,
        notification_statuses: NotificationStatusesListItemResponse[],
      ) => {
        setLoading(false);
        onNext(reservationId, payment_schedule, notification_statuses);
      },
      onError: (message: string) => setError(message),
    });
  };

  const [paymentMethods, setPaymentMethods] = useState<
    Set<PaymentAccountTypeEnum | string>
  >(new Set());

  useEffect(() => {
    if (currentPolicy) {
      setLoading(false);
    }

    if (
      currentPolicy?.payments_policy_items &&
      currentPolicy?.payments_policy_items.length > 0
    ) {
      setPaymentMethods(
        new Set(
          currentPolicy.payments_policy_items
            .map((pp) => {
              if (pp.payment_account_type === PaymentAccountTypeEnum.CASH) {
                return t("Global.PaymentAccounts.cash").toLowerCase();
              } else if (
                pp.payment_account_type === PaymentAccountTypeEnum.BANK_CHECK
              ) {
                return t("Global.PaymentAccounts.bank_check").toLowerCase();
              } else if (
                pp.payment_account_type === PaymentAccountTypeEnum.BANK_TRANSFER
              ) {
                return t("Global.PaymentAccounts.transfer").toLowerCase();
              }

              return capitalizeFirstLetter(pp.payment_account_type ?? "");
            })
            .filter((value) => value.trim()),
        ),
      );
    }
  }, [currentPolicy]);

  const userHasStripe = (): boolean => {
    const allPaymentsAccount = user?.paymentsAccount;

    if (allPaymentsAccount) {
      return allPaymentsAccount.some(
        (paymentAccount) => paymentAccount?.type === "stripe",
      );
    }
    return false;
  };

  const displayPaymentMethods = (
    paymentMethods: Set<PaymentAccountTypeEnum | string>,
  ) => {
    let newPaymentMethods = Array.from(paymentMethods);

    if (newPaymentMethods.length === 0) {
      if (userHasStripe()) {
        return t("Global.PaymentAccounts.stripe");
      }

      return t("Global.PaymentAccounts.cash");
    }
    if (newPaymentMethods.length === 1) return newPaymentMethods[0];
    if (newPaymentMethods.length === 2)
      return newPaymentMethods.join(` ${t("Global.and")} `);

    return (
      newPaymentMethods.slice(0, -1).join(", ") +
      ` ${t("Global.and")} ` +
      newPaymentMethods[newPaymentMethods.length - 1]
    );
  };


  const getTextTrigger = (): string => {
    if (!currentPolicy) {
      return "";
    }

    if (!currentPolicy.is_single_payment) {
      return "";
    }

    const firstItem = currentPolicy.payments_policy_items.at(0);
    if (!firstItem) {
      return "";
    }

    switch (firstItem.trigger) {
      case PaymentPolicyPaymentTriggerEnum.AT_RESERVATION:
        return t("AddReservation.Payment.calendarResa", {
          date: moment(reservation.general?.checkin).format("DD/MM/YYYY"),
        });
      case PaymentPolicyPaymentTriggerEnum.AT_CHECKIN:
        return t("AddReservation.Payment.calendarArrival");
      case PaymentPolicyPaymentTriggerEnum.SPECIFIC_DATE:
        return t("AddReservation.Payment.calendarDate", {
          date: moment(reservation.general?.checkin).format("DD/MM/YYYY"),
        });
    }

    return "";
  };

  return (
    <>
      <div className="flex flex-col h-full">
        <div className="flex-1">
          <div className="flex justify-start pb-12">
            <div className="w-44">
              <Button
                type="secondary"
                LeftIcon={ArrowLeftIcon}
                onClick={onBack}
                disabled={!reservation}
              >
                {t("Global.previousStep")}
              </Button>
            </div>
          </div>

          <p className="mb-8 font-semibold text-xxl text-high-contrast">
            {t("AddReservation.Payment.title")}
          </p>

          <div className="flex flex-col p-3 rounded-lg gap-y-6 bg-element-background border-1 border-element-border">
            {/* PAYMENT CALENDAR */}
            {loading || currentPolicy?.payments_policy_items ? (
              <div className="flex-col items-center space-y-1">
                <div className="flex items-center space-x-1">
                  <CalendarIcon className="size-5" />
                  <p className="text-sm font-light text-low-contrast">
                    {t("AddReservation.Payment.calendarPayment")}
                  </p>
                </div>

                {loading ? (
                  <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
                ) : (
                  <>
                    {currentPolicy?.payments_policy_items ? (
                      <>
                        <p className="text-base font-semibold text-high-contrast">
                          {t(
                            "AddReservation.Payment.calendarPaymentDescription",
                            {
                              nbTimes: getNbTimesPayment(
                                currentPolicy.payments_policy_items.length === 0
                                  ? 1
                                  : currentPolicy.payments_policy_items.length,
                              ),
                              time: getTextTrigger(),
                              paymentMethods:
                                displayPaymentMethods(paymentMethods),
                            },
                          )}
                        </p>
                        {hasAutomaticLinkPayment() &&
                          (currentPolicy.is_single_payment ? (
                            <>
                              {currentPolicy.payments_policy_items.length >
                                0 && (
                                <p className={"text-xs"}>
                                  {t("AddReservation.Payment.msgAuto", {
                                    number: "",
                                  })}
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              {currentPolicy.payments_policy_items.map(
                                (payment, index) => (
                                  <>
                                    <p className={"text-xs"}>
                                      {getDaysDelay(payment) <= 0
                                        ? t("AddReservation.Payment.msgAuto", {
                                            number: index + 1,
                                          })
                                        : t(
                                            "AddReservation.Payment.msgAutoBeforeWithoutDate",
                                            {
                                              number: index + 1,
                                              days: getDaysDelay(payment),
                                            },
                                          )}
                                    </p>
                                  </>
                                ),
                              )}
                            </>
                          ))}
                      </>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}

            {/* CANCELLATION POLICY */}
            {loading || currentPolicy?.is_refundable !== undefined ? (
              <div className="flex-col items-center space-y-1">
                <div className="flex items-center space-x-1">
                  <InputCrossIcon className="size-5" />
                  <p className="text-sm font-light text-low-contrast">
                    {t("AddReservation.Payment.cancellationPolicy")}
                  </p>
                </div>

                {loading ? (
                  <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
                ) : (
                  <>
                    {currentPolicy?.is_refundable !== undefined ? (
                      <p className="text-base font-semibold text-high-contrast">
                        {t(
                          `${
                            Boolean(currentPolicy.is_refundable)
                              ? "AddReservation.Payment.isRefundable"
                              : "AddReservation.Payment.isNotRefundable"
                          }`,
                        )}
                      </p>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}

            {/* DEPOSIT */}
            {loading || currentPolicy?.deposit_value !== undefined ? (
              <div className="flex-col items-center space-y-1">
                <div className="flex items-center space-x-1">
                  <MoneyBagIcon className="size-5" />
                  <p className="text-sm font-light text-low-contrast">
                    {t("AddReservation.Payment.deposit")}
                  </p>
                </div>
                {loading ? (
                  <p className="w-3/4 h-3 rounded-lg bg-slate-300 animate-pulse"></p>
                ) : (
                  <>
                    {currentPolicy?.deposit_value !== undefined ? (
                      <p className="text-base font-semibold text-high-contrast">
                        {currentPolicy?.deposit_value !== null ||
                        currentPolicy?.deposit_value! > 0
                          ? t("AddReservation.Payment.isDeposit", {
                              amount: getFormattedPrice({
                                price: Number(currentPolicy.deposit_value),
                                locale: "fr-FR",
                                decimals: 0,
                              }),
                              paymentMethod: displayDepositPaymentMethod(
                                currentPolicy,
                                t
                              ),
                            })
                          : t("AddReservation.Payment.isNotDeposit")}
                      </p>
                    ) : null}
                  </>
                )}
              </div>
            ) : null}

            <div className="w-max">
              <Button
                type="secondary"
                RightIcon={EditIcon}
                onClick={modal.open}
              >
                {t("AddReservation.Payment.updatePricingConditionsButton")}
              </Button>
            </div>
          </div>
        </div>

        <ErrorMessage>{error}</ErrorMessage>
        <ErrorMessage errorType="FORM">{submitError}</ErrorMessage>

        <div className="flex gap-4 mt-4">
          <Button type="secondary" disabled={!reservation} onClick={onCancel}>
            {t("Global.cancel")}
          </Button>
          <Button
            RightIcon={CheckWhiteIcon}
            disabled={Boolean(submitError)}
            onClick={() => handleNext(paymentPolicyFormValues())}
          >
            {t("AddReservation.Payment.nextStep")}
          </Button>
        </div>
      </div>
    </>
  );
};
