import AlertIcon from "../../../assets/icons/alert-circle-yellow.svg?react";
import React from "react";

export default function NotTaskAssign({
  children,
}: {
  children: React.ReactElement;
}) {
  return (
    <>
      <div
        style={{ borderStyle: "dashed" }}
        className={
          "w-[45px] h-[45px] border rounded-full border-yellow-600 relative"
        }
      >
        <div className={"bg-white absolute -bottom-0.5 -right-0.5"}>
          <div className={"bg-yellow-200/70 rounded-full"}>
            <AlertIcon />
          </div>
        </div>
      </div>
      <p className={"text-yellow-700 font-bold"}>{children}</p>
    </>
  );
}
