import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import paths from "../../constants/paths";
import { ProgressionStepEnum } from "../../enums/GETenums";
import { post } from "../../helpers/APIHelper";
import { Button } from "../Common/Button/Button";

export const CalendarCheckPriceBanner: React.FC<{}> = ({}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const handleCompleteStep = async () => {
    setLoading(true);

    await post(
      `${import.meta.env.VITE_API_URL}${paths.API.COMPLETE_PROGRESSION_STEP}`,
      { progression_step: ProgressionStepEnum.STEP_CHECK_CALENDAR }
    );

    navigate(`${paths.PROGRESSION}`);
  };

  return (
    <div className="flex items-center p-3 mb-3 bg-element-background rounded-6px">
      <div className="flex-1">
        <p className="font-semibold text-high-contrast">
          {t("Calendar.CheckPrice.title")}
        </p>
        <p className="mt-1 font-light text-low-contrast">
          {t("Calendar.CheckPrice.content")}
        </p>
      </div>

      <div>
        <Button onClick={handleCompleteStep} loading={loading}>
          {t("Calendar.CheckPrice.button")}
        </Button>
      </div>
    </div>
  );
};
